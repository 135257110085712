/** @jsx jsx */
/** @jsxRuntime classic */
import { Auth } from '@elevio/kb-kit/lib/components';
import InnerHtml from '@elevio/kb-kit/lib/components/InnerHtml';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import { useTranslation } from '@elevio/kb-kit/lib/hooks';
import { getAgentLoginEnabled } from '@elevio/kb-kit/lib/utils/globals';
import { css, jsx } from '@emotion/react';
import { Fragment } from 'react';
import { toRem } from '../functions';
import { textSmallCSS } from '../variables';
import { themeOptions } from '../variables/themeOptions';
// STYLES
const footerCSS = css([
    textSmallCSS,
    {
        alignItems: 'center',
        color: 'var(--textSubtle)',
        display: 'grid',
        gridTemplateAreas: `'. copyright .'`,
        gridTemplateColumns: `calc((100% - ${toRem(1120)}) / 2) 1fr calc((100% - ${toRem(1120)}) / 2)`,
        paddingBlockEnd: toRem(32),
        '@media (min-width: 768px)': {
            paddingBlockEnd: toRem(48),
        },
        '@media (min-width: 1024px)': {
            paddingBlockEnd: toRem(64),
        },
        '@media (min-width: 1200px)': {
            paddingBlockEnd: toRem(80),
        },
    },
]);
const footerCopyrightCSS = css({
    display: 'block',
    gridArea: 'copyright',
    letterSpacing: '-.02em',
    textAlign: 'center',
});
const footerLinkCSS = css({
    color: 'var(--textSubtle)',
    outline: 'none',
    transitionProperty: 'color',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    '&:hover': {
        color: 'var(--textDefault)',
    },
    '&:active': {
        color: 'var(--textStrong)',
    },
    '&:focus': {
        borderRadius: toRem(4),
        boxShadow: `0 0 0 ${toRem(2)} var(--accent)`,
        marginInlineStart: toRem(-2),
        marginInlineEnd: toRem(-2),
        paddingInlineStart: toRem(2),
        paddingInlineEnd: toRem(2),
    },
});
// FUNCTIONS
export default function Footer({ hideSearch, ...props }) {
    // HOOKS
    const { t } = useTranslation();
    // VARIABLES
    const { customFooterHtml } = themeOptions;
    // RETURN
    return (<Fragment>
      {customFooterHtml && (<InnerHtml className="kb-custom-footer" html={customFooterHtml}/>)}
      <footer className="kb-footer" css={footerCSS} {...props}>
        <small className="kb-footer__copyright" css={footerCopyrightCSS}>
          &copy; <Trans i18nKey="companyName"></Trans>
          {getAgentLoginEnabled() && (<Fragment>
              {` | `}
              <Auth.AgentLoginLogout className="kb-footer__link" css={footerLinkCSS} loginChildren={t('common.agentSignIn')} logoutChildren={t('common.agentSignOut')}/>
            </Fragment>)}
          {!window.whitelabelEnabled && (<Fragment>
              {` | `}
              <Trans i18nKey="footer.poweredBy">
                Elevio by
                <a className="kb-footer__link" css={footerLinkCSS} href="https://www.dixa.com/">
                  Dixa
                </a>
              </Trans>
            </Fragment>)}
        </small>
      </footer>
    </Fragment>);
}
