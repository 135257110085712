import React from 'react';
import ReactDOM from 'react-dom';
import App from '@elevio/kb-kit/lib/App';
import { Global } from '@emotion/react';
import config from './config';
import { bodyFontStyles, codeFontStyles, globalStyles, headingFontStyles, } from './variables';
const target = document.getElementById('kb-target');
if (!target)
    throw new Error('Cant find target div');
ReactDOM.render(<>
    <Global styles={bodyFontStyles}/>
    <Global styles={codeFontStyles}/>
    <Global styles={headingFontStyles}/>
    <Global styles={globalStyles}/>
    <App config={config}/>
  </>, target);
