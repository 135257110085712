/** @jsx jsx */
/** @jsxRuntime classic */
import { useArticleFeedback, useTranslation } from '@elevio/kb-kit/lib/hooks';
import { css, jsx } from '@emotion/react';
import { Alert, Button, Column, Icon, Row, TextArea, TextInput, } from '../components';
import { toRem } from '../functions';
import { heading5CSS, textMediumCSS } from '../variables';
// STYLES
const articleFeedbackCSS = css({
    borderBlockStart: `thin solid var(--border)`,
    padding: toRem(32),
    '@media (min-width: 1200px)': {
        padding: toRem(48),
    },
});
const articleFeedbackTitleCSS = css([
    heading5CSS,
    {
        color: 'var(--textStrong)',
        textAlign: 'center',
    },
]);
const articleFeedbackOptionsButtonCSS = css({
    borderColor: 'var(--textSubtle)',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: 0,
    display: 'block',
    fill: 'var(--textSubtle)',
    outline: 'none',
    position: 'relative',
    transitionProperty: 'background-color, border-color, fill, opacity',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    '&::before': {
        borderColor: 'inherit',
        borderRadius: 'inherit',
        borderStyle: 'inherit',
        borderWidth: toRem(2),
        bottom: 0,
        content: `''`,
        display: 'block',
        left: 0,
        position: 'absolute',
        right: 0,
        top: 0,
    },
    '&:focus': {
        borderColor: 'var(--accent)',
        fill: 'var(--accent)',
    },
    '&:disabled': {
        cursor: 'not-allowed',
        opacity: 0.5,
    },
});
const articleFeedbackOptionsButtonNegativeCSS = css([
    articleFeedbackOptionsButtonCSS,
    {
        '&:hover': {
            borderColor: 'var(--negativeDefault)',
            fill: 'var(--negativeDefault)',
        },
        '&:active': {
            backgroundColor: 'var(--negativeDefault)',
            borderColor: 'var(--negativeDefault)',
            fill: 'var(--accentTextStrong)',
        },
    },
]);
const articleFeedbackOptionsButtonPositiveCSS = css([
    articleFeedbackOptionsButtonCSS,
    {
        '&:hover': {
            borderColor: 'var(--positiveDefault)',
            fill: 'var(--positiveDefault)',
        },
        '&:active': {
            backgroundColor: 'var(--positiveDefault)',
            borderColor: 'var(--positiveDefault)',
            fill: 'var(--accentTextStrong)',
        },
    },
]);
const articleFeedbackOptionsIconCSS = css({
    blockSize: toRem(24),
    inlineSize: toRem(24),
    padding: toRem(16),
});
const articleFeedbackErrorMessageCSS = css([
    textMediumCSS,
    {
        color: 'var(--negativeDefault)',
    },
]);
// FUNCTIONS
export default function ArticleFeedback() {
    // HOOKS
    const { emailError, emailFieldIsHidden, handleEmailChange, handleMessageChange, hasSubmitted, isFeedbackEnabled, isFeedbackFormSubmitting, isLoading, messageError, onFeedbackSkipped, onNegativeReaction, onPositiveReaction, onSubmit, showAnonymousWarning, stage, values, } = useArticleFeedback();
    const { t } = useTranslation();
    // RETURN
    if (isFeedbackEnabled) {
        return (<div className="kb-article-feedback" css={articleFeedbackCSS}>
        {stage === 'initial' && (<ArticleFeedbackInitial isLoading={isLoading} onNegativeReaction={onNegativeReaction} onPositiveReaction={onPositiveReaction}/>)}
        {stage === 'positive' && (<Alert className="kb-article-feedback__alert-positive" icon="smile" message={t('articleFeedback.responsePositive')} tone="positive"/>)}
        {stage === 'negative' && (<ArticleFeedbackNegative emailError={emailError} emailFieldIsHidden={emailFieldIsHidden} handleEmailChange={handleEmailChange} handleMessageChange={handleMessageChange} isFeedbackFormSubmitting={isFeedbackFormSubmitting} messageError={messageError} onFeedbackSkipped={onFeedbackSkipped} onSubmit={onSubmit} showAnonymousWarning={showAnonymousWarning} values={values}/>)}
        {stage === 'submitted' && (<ArticleFeedbackSubmitted hasSubmitted={hasSubmitted}/>)}
      </div>);
    }
    else {
        return null;
    }
}
function ArticleFeedbackInitial({ isLoading, onNegativeReaction, onPositiveReaction, }) {
    // HOOKS
    const { t } = useTranslation();
    // RETURN
    return (<Column className="kb-article-feedback__prompt" gap={16}>
      <h3 className="kb-article-feedback__title" css={articleFeedbackTitleCSS}>
        {t('articleFeedback.prompt')}
      </h3>
      <Row className="kb-article-feedback__prompt-actions" gap={16} justifyContent="center">
        <button className="kb-article-feedback__prompt-button" css={articleFeedbackOptionsButtonNegativeCSS} disabled={isLoading} onClick={onNegativeReaction}>
          <Icon name="thumbs-down" className="kb-article-feedback__prompt-icon" css={articleFeedbackOptionsIconCSS}/>
        </button>
        <button className="kb-article-feedback__prompt-button" css={articleFeedbackOptionsButtonPositiveCSS} disabled={isLoading} onClick={onPositiveReaction}>
          <Icon name="thumbs-up" className="kb-article-feedback__prompt-icon" css={articleFeedbackOptionsIconCSS}/>
        </button>
      </Row>
    </Column>);
}
function ArticleFeedbackNegative({ emailError, emailFieldIsHidden, handleEmailChange, handleMessageChange, isFeedbackFormSubmitting, messageError, onFeedbackSkipped, onSubmit, showAnonymousWarning, values, }) {
    // HOOKS
    const { t } = useTranslation();
    // VARIABLES
    const feedbackInvalid = messageError ? true : undefined;
    const feedbackValue = values.feedback;
    const emailInvalid = emailError ? true : undefined;
    const emailValue = values.email;
    // RETURN
    return (<Column className="kb-article-feedback__form" gap={32}>
      <h3 className="kb-article-feedback__title" css={articleFeedbackTitleCSS}>
        {t('articleFeedback.feedbackPrompt')}
      </h3>
      <Column className="kb-article-feedback__form-row" gap={16}>
        <TextArea autoGrow={true} className="kb-article-feedback__textarea" disabled={isFeedbackFormSubmitting} icon="message-square" invalid={feedbackInvalid} onChange={handleMessageChange} placeholder={t('articleFeedback.feedbackPlaceholder')} required={true} value={feedbackValue}/>
        {messageError && (<div className="kb-article-feedback__error-message" css={articleFeedbackErrorMessageCSS}>
            {messageError}
          </div>)}
      </Column>
      <Column className="kb-article-feedback__form-row" gap={16}>
        {!emailFieldIsHidden && (<TextInput className="kb-article-feedback__input" disabled={isFeedbackFormSubmitting} icon="mail" invalid={emailInvalid} onChange={handleEmailChange} placeholder={t('articleFeedback.emailPlaceholder')} value={emailValue}/>)}
        {emailError && (<div className="kb-article-feedback__error-message" css={articleFeedbackErrorMessageCSS}>
            {emailError}
          </div>)}
      </Column>
      {showAnonymousWarning && (<Alert className="kb-article-feedback__alert-anonymous" icon="help-circle" message={t('articleFeedback.anonymousPrompt')} tone="warning"/>)}
      <Row className="kb-article-feedback__actions" gap={16} justifyContent="start">
        <Button className="kb-article-feedback__button" children={t('articleFeedback.submit')} disabled={isFeedbackFormSubmitting} onClick={onSubmit} primary/>
        <Button className="kb-article-feedback__button" children={t('articleFeedback.skip')} disabled={isFeedbackFormSubmitting} onClick={onFeedbackSkipped}/>
      </Row>
    </Column>);
}
function ArticleFeedbackSubmitted({ hasSubmitted, }) {
    // HOOKS
    const { t } = useTranslation();
    // RETURN
    if (hasSubmitted) {
        return (<Alert className="kb-article-feedback__alert-feedback" icon="smile" message={t('articleFeedback.responseFeedback')} tone="positive"/>);
    }
    else {
        return (<Alert className="kb-article-feedback__alert-negative" icon="frown" message={t('articleFeedback.responseNegative')} tone="negative"/>);
    }
}
