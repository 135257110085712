/** @jsx jsx */
/** @jsxRuntime classic */
import { css, jsx } from '@emotion/react';
import { toRem } from '../functions';
// STYLES
const contentCSS = css({
    backgroundColor: 'var(--backgroundContent)',
    boxShadow: `0 ${toRem(8)} ${toRem(32)} rgba(0,0,0,.08), 0 ${toRem(2)} ${toRem(4)} rgba(0,0,0,.04)`,
    gridArea: 'content',
    '@media (min-width: 768px)': {
        borderRadius: toRem(12),
    },
});
// FUNCTIONS
export default function Content(props) {
    return <article className="kb-content" css={contentCSS} {...props}/>;
}
