/** @jsx jsx */
/** @jsxRuntime classic */
import { css, jsx, keyframes } from '@emotion/react';
import classNames from 'classnames';
import { Icon } from '../components';
import { toRem } from '../functions';
// STYLES
const spin = keyframes({
    from: {
        transform: 'rotate(0deg)',
    },
    to: {
        transform: 'rotate(360deg)',
    },
});
const loadingSpinnerCSS = css({
    animationName: spin,
    animationDuration: '800ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    blockSize: toRem(20),
    fill: 'var(--accent)',
    inlineSize: toRem(20),
    padding: toRem(4),
});
// FUNCTIONS
export default function LoadingSpinner({ className, ...props }) {
    return (<Icon className={classNames('kb-loading-spinner', className)} css={loadingSpinnerCSS} {...props} name="loading"/>);
}
