/** @jsx jsx */
/** @jsxRuntime classic */
import { useEffect } from 'react';
import { css, jsx } from '@emotion/react';
import { Icon } from '.';
const lightboxContainerCSS = css({
    backgroundColor: 'rgba(0,0,0,.75)',
    inset: 0,
    position: 'fixed',
    zIndex: 9999,
});
const lightboxCloseCSS = css({
    borderRadius: '.25rem',
    cursor: 'pointer',
    fill: 'var(--accentTextDefault)',
    insetBlockStart: '0',
    insetInlineEnd: '0',
    padding: '1.25rem',
    position: 'absolute',
    width: '1.5rem',
});
const lightboxModalContentCSS = css({
    alignItems: 'center',
    blockSize: '100vh',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    inlineSize: '100vw',
    justifyContent: 'center',
    margin: 'auto',
    padding: '4rem',
});
const lightboxImageCSS = css({
    backgroundColor: 'var(--backgroundContent)',
    cursor: 'default',
    display: 'block',
    maxBlockSize: '100%',
    maxInlineSize: '100%',
    objectFit: 'contain',
});
const LightBox = ({ image, isShowing, hide }) => {
    useEffect(() => {
        const keyHandler = ({ key }) => {
            if (key === 'Escape')
                hide();
        };
        window.addEventListener('keydown', keyHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', keyHandler);
        };
    }, [hide]);
    if (!image || !isShowing) {
        return null;
    }
    return (<div className="lightbox-modal" css={lightboxContainerCSS} onClick={hide}>
      <span className="lightbox-close" css={lightboxCloseCSS} onClick={hide}>
        <Icon name="x"/>
      </span>

      <div className="lightbox-modal-content" css={lightboxModalContentCSS}>
        <img src={image.src} className="lightbox-image" css={lightboxImageCSS} alt={image.alt} onClick={(e) => e.stopPropagation()}/>
      </div>
    </div>);
};
export default LightBox;
