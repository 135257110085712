/** @jsx jsx */
/** @jsxRuntime classic */
import { css, jsx } from '@emotion/react';
import { toRem } from '../functions';
// STYLES
const asideCSS = css({
    display: 'grid',
    gridArea: 'aside',
    gridAutoFlow: 'row',
    padding: toRem(32),
    rowGap: toRem(64),
    '@media (min-width: 768px)': {
        padding: 0,
    },
    '@media (min-width: 1024px)': {
        paddingInlineStart: toRem(32),
    },
});
// FUNCTIONS
export default function Aside(props) {
    return <aside className="kb-aside" css={asideCSS} {...props}/>;
}
