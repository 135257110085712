/** @jsx jsx */
/** @jsxRuntime classic */
import { css, jsx } from '@emotion/react';
import { Category } from '@elevio/kb-kit/lib/components';
import { useCategory, useTranslation } from '@elevio/kb-kit/lib/hooks';
import { Icon, Skeleton } from '../components';
import { toRem } from '../functions';
import { heading5CSS, textSmallCSS } from '../variables';
// STYLES
const cardCSS = css({
    alignItems: 'center',
    backgroundColor: 'var(--backgroundContent)',
    boxShadow: `0 ${toRem(2)} ${toRem(4)} rgba(0,0,0,.04), 0 ${toRem(8)} ${toRem(32)} rgba(0,0,0,.08)`,
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
    padding: toRem(32),
    position: 'relative',
    textAlign: 'center',
    transitionProperty: 'box-shadow, transform',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    '@media (min-width: 768px)': {
        borderRadius: toRem(8),
    },
    '&:hover': {
        '@media (min-width: 768px)': {
            boxShadow: `0 ${toRem(4)} ${toRem(8)} rgba(0,0,0,.08), 0 ${toRem(12)} ${toRem(48)} rgba(0,0,0,.12)`,
            transform: `translate3d(0, ${toRem(-4)}, 0)`,
        },
    },
    '&:focus': {
        '&::before': {
            borderColor: 'var(--accent)',
            borderStyle: 'solid',
            borderWidth: toRem(4),
            bottom: 0,
            content: `''`,
            display: 'block',
            left: 0,
            pointerEvents: 'none',
            position: 'absolute',
            right: 0,
            top: 0,
            '@media (min-width: 768px)': {
                borderRadius: toRem(12),
                border: `${toRem(4)} solid var(--accent)`,
                bottom: toRem(-4),
                boxShadow: `0 0 0 ${toRem(4)} var(--backgroundWebsite)`,
                left: toRem(-4),
                right: toRem(-4),
                top: toRem(-4),
            },
        },
    },
});
const circleCSS = css({
    backgroundColor: 'var(--accent)',
    borderRadius: '50%',
    fill: 'var(--accentTextStrong)',
    marginBlockEnd: toRem(24),
    padding: toRem(20),
});
const iconCSS = css({
    blockSize: toRem(24),
    inlineSize: toRem(24),
});
const titleCSS = css([
    heading5CSS,
    {
        color: 'var(--textStrong)',
    },
]);
const infoCSS = css([
    textSmallCSS,
    {
        color: 'var(--textSubtle)',
        marginBlockStart: 'auto',
        paddingBlockStart: toRem(32),
        '&::before': {
            borderBlockStart: 'thin solid var(--border)',
            content: `''`,
            display: 'block',
            inlineSize: toRem(80),
            marginBlockEnd: toRem(16),
            marginInlineStart: 'auto',
            marginInlineEnd: 'auto',
        },
    },
]);
// FUNCTIONS
export default function CategoryCard({ id }) {
    var _a;
    // HOOKS
    const category = useCategory({ categoryId: id });
    const { t } = useTranslation();
    // VARIABLES
    const subCategoriesCount = ((_a = category === null || category === void 0 ? void 0 : category.subCategories) === null || _a === void 0 ? void 0 : _a.length) || 0;
    const articlesCount = (category === null || category === void 0 ? void 0 : category.articles.length) || 0;
    // RETURN
    if (!category) {
        return null;
    }
    return (<Category.Link categoryId={id} className="kb-category-card" css={cardCSS}>
      <div className="kb-category-card__icon-wrapper" css={circleCSS}>
        <Icon className="kb-category-card__icon" css={iconCSS} name="folder"/>
      </div>
      <h2 className="kb-category-card__title" css={titleCSS}>
        {category.title}
      </h2>
      <p className="kb-category-card__info" css={infoCSS}>
        {subCategoriesCount > 0 && (<span className="kb-category-card__info-subcategories">
            {t('common.categoryWithCount', { count: subCategoriesCount })}
          </span>)}
        {subCategoriesCount > 0 && articlesCount > 0 && (<span className="kb-category-card__info-divider">
            {' '}
            &nbsp;|&nbsp;{' '}
          </span>)}
        {articlesCount > 0 && (<span className="kb-category-card__info-articles">
            {t('common.articleWithCount', { count: articlesCount })}
          </span>)}
      </p>
    </Category.Link>);
}
export function CategoryCardPlaceholder() {
    return (<div className="kb-category-card placeholder" css={cardCSS} role="presentation">
      <div className="kb-category-card__icon-wrapper placeholder" css={circleCSS}>
        <Icon className="kb-category-card__icon placeholder" css={iconCSS} name="folder"/>
      </div>
      <Skeleton innerHeight={18} outerHeight={28} width={240}/>
      <div className="kb-category-card__info placeholder" css={infoCSS} role="presentation">
        <Skeleton innerHeight={12} outerHeight={20} width={160}/>
      </div>
    </div>);
}
