/** @jsx jsx */
/** @jsxRuntime classic */
import { Article } from '@elevio/kb-kit/lib/components';
import { useSearch, useSearchResults, useTranslation, } from '@elevio/kb-kit/lib/hooks';
import { css, jsx } from '@emotion/react';
import classNames from 'classnames';
import { useEffect, useRef, useState, } from 'react';
import { Icon, LoadingSpinner } from '../components';
import { toRem } from '../functions';
// STYLES
const searchCSS = css({
    backgroundColor: 'var(--defaultBackgroundContent)',
    borderRadius: toRem(6),
    boxShadow: `0 0 0 ${toRem(4)} rgba(0,0,0,.24)`,
    boxSizing: 'border-box',
    color: 'var(--defaultTextDefault)',
    display: 'flex',
    fill: 'var(--defaultTextSubtle)',
    fontWeight: 600,
    letterSpacing: '-.02em',
    padding: toRem(12),
    position: 'relative',
    transitionProperty: 'box-shadow',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    '@media (min-width: 1024px)': {
        padding: toRem(8),
    },
    '&:hover': {
        boxShadow: `0 0 0 ${toRem(4)} rgba(0,0,0,.48)`,
    },
    '&:focus-within': {
        boxShadow: `0 0 0 ${toRem(4)} rgba(0,0,0,1)`,
    },
    '&[hidden]': {
        display: 'none',
    },
    '&[data-large="true"]': {
        boxShadow: `0 0 0 ${toRem(4)} rgba(0,0,0,.24),
                    0 ${toRem(2)} ${toRem(4)} rgba(0,0,0,.16),
                    0 ${toRem(8)} ${toRem(32)} rgba(0,0,0,.16)`,
        fontSize: toRem(16),
        lineHeight: toRem(28),
        margin: toRem(4),
        padding: toRem(12),
        '&:hover': {
            boxShadow: `0 0 0 ${toRem(4)} rgba(0,0,0,.48),
                        0 ${toRem(2)} ${toRem(4)} rgba(0,0,0,.16),
                        0 ${toRem(8)} ${toRem(32)} rgba(0,0,0,.16)`,
        },
        '&:focus-within': {
            boxShadow: `0 0 0 ${toRem(4)} rgba(0,0,0,1),
                        0 ${toRem(2)} ${toRem(4)} rgba(0,0,0,.16),
                        0 ${toRem(8)} ${toRem(32)} rgba(0,0,0,.16)`,
        },
    },
});
const searchButtonCSS = css({
    borderRadius: toRem(4),
    fill: 'var(--defaultTextSubtle)',
    outline: 'none',
    transitionProperty: 'fill',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    '&:hover': {
        fill: 'var(--defaultTextDefault)',
    },
    '&:active': {
        fill: 'var(--defaultTextStrong)',
    },
    '&:focus': {
        boxShadow: `0 0 0 ${toRem(2)} var(--defaultAccent)`,
    },
});
const searchIconCSS = css({
    blockSize: toRem(16),
    flexShrink: 0,
    inlineSize: toRem(16),
    padding: toRem(4),
    '[data-large="true"] &': {
        blockSize: toRem(20),
        inlineSize: toRem(20),
    },
});
const searchInputCSS = css({
    flexGrow: 1,
    outline: 'none',
    paddingInlineStart: toRem(8),
    paddingInlineEnd: toRem(8),
});
const searchResultsDropdownCSS = css({
    backgroundColor: 'var(--defaultBackgroundContent)',
    borderRadius: toRem(8),
    boxShadow: `0 ${toRem(8)} ${toRem(32)} rgba(0,0,0,.08), 0 ${toRem(2)} ${toRem(4)} rgba(0,0,0,.04)`,
    left: 0,
    padding: toRem(8),
    position: 'absolute',
    right: 0,
    top: `calc(100% + ${toRem(8)})`,
    zIndex: 2,
    '[data-large="true"] &': {
        borderRadius: toRem(12),
        padding: toRem(12),
    },
});
const searchResultsItemCSS = css({
    borderRadius: toRem(6),
    color: 'var(--defaultTextSubtle)',
    display: 'flex',
    fill: 'var(--defaultTextSubtle)',
    outline: 'none',
    padding: toRem(8),
    transitionProperty: 'color, fill',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    '[data-large="true"] &': {
        borderRadius: toRem(6),
        padding: toRem(12),
    },
    '&:hover': {
        color: 'var(--defaultTextDefault)',
        fill: 'var(--defaultTextDefault)',
    },
    '&:active': {
        color: 'var(--defaultTextStrong)',
        fill: 'var(--defaultTextStrong)',
    },
    '&:focus': {
        boxShadow: `0 0 0 ${toRem(2)} var(--defaultAccent)`,
        color: 'var(--defaultAccent)',
        fill: 'var(--defaultAccent)',
    },
});
const searchResultsIconCSS = css({
    blockSize: toRem(16),
    flexShrink: 0,
    inlineSize: toRem(16),
    padding: toRem(4),
    '[data-large="true"] &': {
        blockSize: toRem(20),
        inlineSize: toRem(20),
    },
});
const searchResultsTitleCSS = css({
    flexGrow: 1,
    paddingInlineStart: toRem(8),
    paddingInlineEnd: toRem(8),
});
const searchResultsArrowCSS = css([
    searchResultsIconCSS,
    {
        visibility: 'hidden',
        ':focus &': {
            visibility: 'visible',
        },
    },
]);
const searchNoResultsMessageCSS = css({
    fontWeight: 'normal',
    textAlign: 'center',
});
// FUNCTIONS
export default function Search({ className, hidden, large, onClick, onClose, ...props }) {
    // REFS
    const searchInputRef = useRef(null);
    // HOOKS
    const { isOpen, onChange, onCloseDropdown, onKeyUp, onOpenDropdown, onSubmit, } = useSearch();
    const { isFetching, results, searchTerm } = useSearchResults();
    const { t } = useTranslation();
    // STATE
    const [searchValue, setSearchValue] = useState(searchTerm || '');
    // VARIABLES
    const resultsTrimmed = (results && results.slice(0, 5)) || [];
    // FUNCTIONS
    function searchOnClick(event) {
        var _a;
        event.stopPropagation();
        (_a = searchInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        onClick && onClick(event);
    }
    function searchOnKeyDown(event) {
        if (onClose && event.key === 'Escape') {
            event.preventDefault();
            onClose && onClose();
        }
        else if (event.key === 'Enter') {
            event.preventDefault();
            onOpenDropdown();
        }
    }
    function searchOnReset() {
        var _a;
        setSearchValue('');
        (_a = searchInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }
    function searchOnSubmit(event) {
        event.preventDefault();
        if (searchValue) {
            onSubmit();
        }
    }
    function searchInputOnChange(event) {
        setSearchValue(event.currentTarget.value);
        onChange(event);
    }
    function searchInputOnKeyDown(event) {
        if (onClose &&
            ((event.key === 'Tab' && !searchValue) ||
                (event.key === 'Tab' && event.shiftKey))) {
            event.preventDefault();
            onClose && onClose();
        }
    }
    function searchSubmitOnKeyDown(event) {
        if (!isOpen && onClose && event.key === 'Tab' && event.shiftKey === false) {
            event.preventDefault();
            onClose && onClose();
        }
    }
    function SearchDropdownOnClose() {
        onClose ? onClose() : onCloseDropdown();
    }
    function windowOnClick(event) {
        event.stopPropagation();
        onClose && onClose();
    }
    // EFFECTS
    useEffect(() => {
        var _a;
        if (onClose && !hidden) {
            (_a = searchInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            window.addEventListener('click', windowOnClick);
        }
        return () => {
            window.removeEventListener('click', windowOnClick);
        };
    }, [hidden]);
    // RETURN
    return (<form autoComplete="off" className={classNames('kb-search', className)} css={searchCSS} data-large={large} hidden={hidden} onClick={searchOnClick} onKeyDown={searchOnKeyDown} onReset={searchOnReset} onSubmit={searchOnSubmit} role="search" dir="auto" {...props}>
      <Icon className="kb-search__icon" name="search" css={searchIconCSS}/>
      <input aria-label={t('search.placeholder')} className="kb-search__input" css={searchInputCSS} defaultValue={searchValue} id="input" onChange={searchInputOnChange} onKeyDown={searchInputOnKeyDown} onKeyUp={onKeyUp} placeholder={t('search.placeholder')} ref={searchInputRef} type="text"/>
      {isFetching ? (<LoadingSpinner className="kb-search__spinner" css={searchIconCSS}/>) : (<button aria-label={t('search.reset')} className="kb-search__button" css={searchButtonCSS} hidden={!searchValue} type="reset">
          <Icon name="x" className="kb-search__button-icon" css={searchIconCSS}/>
        </button>)}
      <button aria-label={t('search.submit')} className="kb-search__button" css={searchButtonCSS} hidden={!searchValue} type="submit" onKeyDown={searchSubmitOnKeyDown}>
        <Icon name="corner-down-left" className="kb-search__button-icon" css={searchIconCSS}/>
      </button>
      {isOpen && results && (<SearchResultsDropdown onClose={SearchDropdownOnClose} results={resultsTrimmed} searchTerm={searchTerm}/>)}
    </form>);
}
function SearchResultsDropdown({ onClose, results, searchTerm, }) {
    // HOOKS
    const { t } = useTranslation();
    // FUNCTIONS
    function onKeyDown(event, index) {
        if (index === results.length - 1 &&
            event.key === 'Tab' &&
            !event.shiftKey) {
            onClose();
        }
        else if (event.key === 'Enter') {
            event.stopPropagation();
        }
    }
    // RETURN
    return (<div className="kb-search__results" css={searchResultsDropdownCSS}>
      {results.length > 0 ? (<ul className="kb-search__results-wrapper">
          {results.map((result, index) => {
                const { id, title } = result;
                return (<li className="kb-search__results-item" key={index}>
                <Article.Link articleId={id} className="kb-search__results-link" css={searchResultsItemCSS} onKeyDown={(event) => onKeyDown(event, index)}>
                  <Icon className="kb-search__results-icon" css={searchResultsIconCSS} name="file-text"/>
                  <div className="kb-search__results-title" css={searchResultsTitleCSS}>
                    {title}
                  </div>
                  <Icon className="kb-search__results-icon" css={searchResultsArrowCSS} name="arrow-right"/>
                </Article.Link>
              </li>);
            })}
        </ul>) : (<p css={searchNoResultsMessageCSS} className="kb-search__results-noResultsMessage">
          {t('search.resultsWithCount', {
                count: 0,
                searchTerm: searchTerm,
            })}
        </p>)}
    </div>);
}
