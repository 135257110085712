/** @jsx jsx */
/** @jsxRuntime classic */
import { css, jsx } from '@emotion/react';
import { toRem } from '../functions';
// STYLES
const headerCSS = css({
    backgroundColor: 'var(--accent)',
    color: 'var(--accentTextDefault)',
    padding: `${toRem(16)} ${toRem(32)} ${toRem(32)} ${toRem(32)}`,
    '@media (min-width: 768px)': {
        columnGap: toRem(16),
        display: 'grid',
        gridTemplateAreas: `'. content content content content content content content content content content .'`,
        gridTemplateColumns: `repeat(12, minmax(0, ${toRem(64)}))`,
        justifyContent: 'center',
        padding: `0 ${toRem(32)} ${toRem(48)} ${toRem(32)}`,
    },
    '@media (min-width: 1024px)': {
        columnGap: toRem(24),
        gridTemplateAreas: `'. . content content content content content content content content . .'`,
        paddingBlockEnd: toRem(64),
        paddingInlineStart: toRem(40),
        paddingInlineEnd: toRem(40),
    },
    '@media (min-width: 1200px)': {
        columnGap: toRem(32),
        gridTemplateAreas: `'. . . content content content content content content . . .'`,
        paddingBlockEnd: toRem(80),
        paddingInlineStart: toRem(0),
        paddingInlineEnd: toRem(0),
    },
});
const headerContentCSS = css({
    gridArea: 'content',
});
// FUNCTIONS
export default function Header({ children, ...props }) {
    return (<header className="kb-header" css={headerCSS} dir="auto" {...props}>
      <div className="kb-header__content" css={headerContentCSS}>
        {children}
      </div>
    </header>);
}
