/** @jsx jsx */
/** @jsxRuntime classic */
import { css, jsx } from '@emotion/react';
import classNames from 'classnames';
import { Icon } from '../components';
import { toRem } from '../functions';
import { iconMediumCSS, textMediumCSS } from '../variables';
// STYLES
const alertCSS = ({ tone }) => css({
    borderRadius: toRem(6),
    display: 'flex',
    justifyContent: 'space-between',
    padding: toRem(20),
    textAlign: 'left',
    ...(tone === 'negative' && {
        backgroundColor: 'var(--negativeSubtle)',
        color: 'var(--negativeStrong)',
        fill: 'var(--negativeStrong)',
    }),
    ...(tone === 'positive' && {
        backgroundColor: 'var(--positiveSubtle)',
        color: 'var(--positiveStrong)',
        fill: 'var(--positiveStrong)',
    }),
    ...(tone === 'warning' && {
        backgroundColor: 'var(--warningSubtle)',
        color: 'var(--warningStrong)',
        fill: 'var(--warningStrong)',
    }),
});
const alertIconCSS = css([
    iconMediumCSS,
    {
        marginInlineEnd: toRem(4),
    },
]);
const alertMessage = css([
    textMediumCSS,
    {
        fontWeight: 600,
        paddingInlineStart: toRem(8),
        paddingInlineEnd: toRem(8),
    },
]);
const clearAlertButton = css({
    padding: toRem(1),
});
// FUNCTIONS
export default function Alert({ className, icon, message, tone = 'warning', onClear, ...props }) {
    return (<div className={classNames('kb-alert', `kb-alert--${tone}`, className)} css={alertCSS({ tone })} role="alert" {...props}>
      <div></div>

      <div>
        {icon && <Icon css={alertIconCSS} name={icon}/>}
        <div css={alertMessage}>{message}</div>
      </div>

      {onClear && (<button css={clearAlertButton} onClick={onClear}>
          <Icon name="x" css={alertIconCSS}/>
        </button>)}
    </div>);
}
