/** @jsx jsx */
/** @jsxRuntime classic */
import { css, jsx } from '@emotion/react';
import { toRem } from '../functions';
// STYLES
const flexCSS = ({ direction, gap, inline, justifyContent }) => {
    const display = inline ? 'inline-grid' : 'grid';
    const gridAutoFlow = direction === 'row' ? 'column' : undefined;
    return css({
        display,
        gridAutoFlow,
        gap: toRem(gap),
        justifyContent,
    });
};
// FUNCTIONS
export default function Flex({ children, direction, gap, inline, justifyContent, ...props }) {
    return (<div css={flexCSS({ direction, gap, inline, justifyContent })} children={children} {...props}/>);
}
export function Column(props) {
    return <Flex direction="column" {...props}/>;
}
export function Row(props) {
    return <Flex direction="row" {...props}/>;
}
