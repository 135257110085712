/** @jsx jsx */
/** @jsxRuntime classic */
import { Article, Category, Meta } from '@elevio/kb-kit/lib/components';
import { useBreadcrumbs, useTranslation } from '@elevio/kb-kit/lib/hooks';
import { css, jsx } from '@emotion/react';
import { Fragment } from 'react';
import { Icon } from '../components';
import { toRem } from '../functions';
import { iconSmallCSS, textSmallCSS } from '../variables';
// STYLES
const breadcrumbsCSS = css([
    textSmallCSS,
    {
        color: 'var(--textSubtle)',
        display: 'flex',
        fill: 'var(--textSubtle)',
        flexWrap: 'wrap',
        fontWeight: 600,
        gridArea: 'breadcrumbs',
        justifyContent: 'start',
        padding: toRem(32),
        rowGap: toRem(8),
        '@media (min-width: 768px)': {
            padding: 0,
        },
    },
]);
const breadcrumbsDividerCSS = css({
    flexShrink: 0,
    opacity: 0.5,
    paddingInlineStart: toRem(8),
    paddingInlineEnd: toRem(8),
});
const breadcrumbLinkCSS = css({
    outline: 'none',
    transitionProperty: 'color',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    '&:hover': {
        color: 'var(--textDefault)',
        textDecoration: 'underline',
    },
    '&:active': {
        color: 'var(--textStrong)',
    },
    '&:focus': {
        borderRadius: toRem(3),
        boxShadow: `0 0 0 ${toRem(2)} var(--accent)`,
        marginInlineStart: toRem(-2),
        marginInlineEnd: toRem(-2),
        paddingInlineStart: toRem(2),
        paddingInlineEnd: toRem(2),
    },
    '&[aria-current="page"]': {
        opacity: 0.5,
    },
});
const breadcrumbHomeLinkCSS = css({
    outline: 'none',
    transitionProperty: 'fill',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    '&:hover': {
        fill: 'var(--textDefault)',
        textDecoration: 'underline',
    },
    '&:active': {
        fill: 'var(--textStrong)',
    },
    '&:focus': {
        borderRadius: toRem(3),
        boxShadow: `0 0 0 ${toRem(2)} var(--accent)`,
    },
});
// FUNCTIONS
export default function Breadcrumbs(props) {
    // HOOKS
    const breadcrumbs = useBreadcrumbs();
    const { t } = useTranslation();
    // RETURN
    return (<nav className="kb-breadcrumbs" css={breadcrumbsCSS} {...props}>
      {breadcrumbs.map((crumb, index) => {
            if (crumb.linkType === 'home') {
                return (<Meta.HomeLink className="kb-breadcrumbs__home-link" css={breadcrumbHomeLinkCSS} key={index}>
              <Icon name="home" className="kb-breadcrumbs__home-icon" css={iconSmallCSS}/>
            </Meta.HomeLink>);
            }
            else if (crumb.linkType === 'category') {
                return (<Fragment key={index}>
              {index > 0 && (<div className="kb-breadcrumbs__divider" css={breadcrumbsDividerCSS}>
                  /
                </div>)}
              <Category.Link categoryId={crumb.linkId} className="kb-breadcrumbs__link" css={breadcrumbLinkCSS}>
                {crumb.title}
              </Category.Link>
            </Fragment>);
            }
            else if (crumb.linkType === 'article') {
                return (<Fragment key={index}>
              {index > 0 && (<div className="kb-breadcrumbs__divider" css={breadcrumbsDividerCSS}>
                  /
                </div>)}
              <Article.Link articleId={crumb.linkId} className="kb-breadcrumbs__link" css={breadcrumbLinkCSS}>
                {crumb.title}
              </Article.Link>
            </Fragment>);
            }
            else if (crumb.linkType === 'search') {
                return (<Fragment key={index}>
              {index > 0 && (<div className="kb-breadcrumbs__divider" css={breadcrumbsDividerCSS}>
                  /
                </div>)}
              <div className="kb-breadcrumbs__text">{t('search.label')}</div>
            </Fragment>);
            }
        })}
    </nav>);
}
