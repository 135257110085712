export const assetsLocation = window.assetsLocation;
const defaults = {
    imageUrl: '',
    customCSS: '',
    customJS: '',
    customHeaderHtml: '',
    customFooterHtml: '',
    fontHeadings: 'System Default',
    fontBody: 'System Default',
    fontCode: 'System Default',
    colorWebsiteBg: '#F7F7F7',
    colorPageBg: '#FFFFFF',
    colorAccent: '#333333',
    colorLink: '#FF7700',
    colorText: '#333333',
    colorAccentText: '#FFFFFF',
};
export const themeOptions = Object.assign({}, defaults, window.themeOptions);
