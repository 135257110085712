import { css } from '@emotion/react';
import tinycolor from 'tinycolor2';
import { toRem } from '../functions';
import { themeOptions } from './themeOptions';
import { bodyFontFamily, codeFontFamily, headingFontFamily } from './fonts';
import { heading1CSS, heading2CSS, heading3CSS, heading4CSS, heading5CSS, heading6CSS, } from './typography';
const accentBackgroundDropdownColor = tinycolor(themeOptions.colorAccent);
const accentBackgroundDropdown = accentBackgroundDropdownColor.getBrightness() < 50
    ? accentBackgroundDropdownColor.lighten(10).toString()
    : accentBackgroundDropdownColor.darken(20).toString();
export const globalStyles = css({
    /* RESET */
    ':root': {
        // ACCENT
        '--defaultAccent': themeOptions.colorAccent,
        '--accentAccent': themeOptions.colorAccentText,
        '--accent': 'var(--defaultAccent)',
        // BACKGROUND
        '--defaultBackgroundWebsite': themeOptions.colorWebsiteBg,
        '--defaultBackgroundContent': themeOptions.colorPageBg,
        '--defaultBackgroundDropdown': themeOptions.colorPageBg,
        '--accentBackground': themeOptions.colorAccent,
        '--accentBackgroundContent': themeOptions.colorAccent,
        '--accentBackgroundDropdown': accentBackgroundDropdown,
        '--backgroundWebsite': 'var(--defaultBackgroundWebsite)',
        '--backgroundContent': 'var(--defaultBackgroundContent)',
        '--backgroundDropdown': 'var(--defaultBackgroundDropdown)',
        // BORDER
        '--border': tinycolor(themeOptions.colorText).setAlpha(0.08).toString(),
        // FONT
        '--fontFamilyHeadings': headingFontFamily,
        '--fontFamilyBody': bodyFontFamily,
        '--fontFamilyCode': codeFontFamily,
        // TEXT
        '--defaultTextStrong': themeOptions.colorText,
        '--defaultTextDefault': tinycolor(themeOptions.colorText)
            .setAlpha(0.9)
            .toString(),
        '--defaultTextSubtle': tinycolor(themeOptions.colorText)
            .setAlpha(0.7)
            .toString(),
        '--accentTextStrong': themeOptions.colorAccentText,
        '--accentTextDefault': tinycolor(themeOptions.colorAccentText)
            .setAlpha(0.8)
            .toString(),
        '--accentTextSubtle': tinycolor(themeOptions.colorAccentText)
            .setAlpha(0.6)
            .toString(),
        '--textStrong': 'var(--defaultTextStrong)',
        '--textDefault': 'var(--defaultTextDefault)',
        '--textSubtle': 'var(--defaultTextSubtle)',
        '--textLink': themeOptions.colorLink,
        // TONE
        '--negativeSubtle': '#FDEEEF',
        '--negativeDefault': '#E12D39',
        '--negativeStrong': '#AB1721',
        '--positiveSubtle': '#F3FDEE',
        '--positiveDefault': '#67E12D',
        '--positiveStrong': '#419E16',
        '--warningSubtle': '#FDF9EE',
        '--warningDefault': '#E1AF2D',
        '--warningStrong': '#A17911',
    },
    html: {
        fontFamily: 'var(--fontFamilyBody)',
        scrollPaddingBlockStart: toRem(96),
        textRendering: 'optimizeLegibility',
        MozOsxFontSmoothing: 'grayscale',
        WebkitFontSmoothing: 'antialiased',
        textSizeAdjust: 'none',
        '@media (min-width: 768px)': {
            scrollPaddingBlockStart: toRem(104),
        },
        '@media (min-width: 1024px)': {
            scrollPaddingBlockStart: toRem(136),
        },
        '@media (min-width: 1200px)': {
            scrollPaddingBlockStart: toRem(152),
        },
    },
    body: {
        backgroundColor: 'var(--backgroundWebsite)',
        margin: 0,
    },
    'h1, h2, h3, h4, h5, h6': {
        fontFamily: 'var(--fontFamilyHeadings)',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },
    p: {
        marginBlockStart: 0,
        marginBlockEnd: 0,
    },
    'ol, ul': {
        listStyle: 'none',
        marginBlockStart: 0,
        marginBlockEnd: 0,
        paddingInlineStart: 0,
    },
    hr: {
        border: 'none',
        margin: 0,
    },
    small: {
        fontSize: 'inherit',
    },
    button: {
        appearance: 'none',
        background: 'none',
        border: 'none',
        borderRadius: 0,
        boxShadow: 'none',
        color: 'inherit',
        cursor: 'pointer',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        margin: 0,
        padding: 0,
        textAlign: 'inherit',
    },
    svg: {
        blockSize: 'auto',
        clipRule: 'evenodd',
        display: 'block',
        fill: 'inherit',
        fillRule: 'evenodd',
    },
    a: {
        color: 'inherit',
        display: 'inline-block',
        textDecoration: 'none',
    },
    'input, select, textarea': {
        appearance: 'none',
        background: 'none',
        border: 'none',
        borderRadius: 0,
        boxShadow: 'none',
        color: 'inherit',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        letterSpacing: 'inherit',
        lineHeight: 'inherit',
        margin: 0,
        minWidth: 0,
        padding: 0,
    },
    '.article': {
        color: 'var(--textDefault)',
        fontSize: toRem(14),
        lineHeight: toRem(24),
        padding: '0 !important',
        '@media (min-width: 1200px)': {
            fontSize: toRem(16),
            lineHeight: toRem(28),
        },
        p: {
            marginBlockStart: '1.5em',
            marginBlockEnd: '1.5em',
            '&:first-child': {
                marginBlockStart: 0,
            },
            '&:last-child': {
                marginBlockEnd: 0,
            },
        },
        'ul, ol': {
            paddingInlineStart: '3em',
            marginBlockStart: '1.5em',
            marginBlockEnd: '1.5em',
            'ul, ol': {
                marginBlockStart: '.75em',
                marginBlockEnd: '.75em',
            },
            '&:first-child': {
                marginBlockStart: 0,
            },
            '&:last-child': {
                marginBlockEnd: 0,
            },
        },
        ul: {
            listStyle: 'disc',
        },
        ol: {
            listStyle: 'decimal',
            ol: {
                listStyle: 'lower-roman',
            },
        },
        li: {
            marginBlockStart: '.75em',
            marginBlockEnd: '.75em',
        },
        'a, [data-elevio-article]': {
            color: 'var(--textLink)',
            cursor: 'pointer',
            fontWeight: 600,
            outline: 'none',
            textDecoration: 'underline',
            transitionProperty: 'opacity',
            transitionDuration: '200ms',
            transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
            '&:hover': {
                opacity: 0.75,
            },
            '&:active': {
                opacity: 0.75,
            },
            '&:focus': {
                borderRadius: toRem(3),
                boxShadow: `0 0 0 ${toRem(2)} var(--accent)`,
                marginInlineStart: toRem(-2),
                marginInlineEnd: toRem(-2),
                paddingInlineStart: toRem(2),
                paddingInlineEnd: toRem(2),
            },
        },
        'h1, h2, h3, h4, h5, h6': {
            color: 'var(--textStrong)',
            marginBlockStart: '1.5em',
            marginBlockEnd: '1.5em',
            '&:first-child': {
                marginBlockStart: 0,
            },
            '&:last-child': {
                marginBlockEnd: 0,
            },
        },
        h1: heading1CSS,
        h2: heading2CSS,
        h3: heading3CSS,
        h4: heading4CSS,
        h5: heading5CSS,
        h6: heading6CSS,
        blockquote: {
            borderInlineStart: '.25rem solid var(--border)',
            fontStyle: 'italic',
            margin: `3em 0`,
            padding: `${toRem(16)} ${toRem(48)}`,
            '&:first-child': {
                marginBlockStart: 0,
            },
            '&:last-child': {
                marginBlockEnd: 0,
            },
        },
        '.elevio-box-error, .elevio-box-info, .elevio-box-success, .elevio-box-warning': {
            borderWidth: 0,
            borderRadius: toRem(6),
            fontStyle: 'normal',
            margin: '1.5em 0',
            padding: `${toRem(16)} ${toRem(20)}`,
            '&:first-child': {
                marginBlockStart: 0,
            },
            '&:last-child': {
                marginBlockEnd: 0,
            },
        },
        '.elevio-box-info': {
            '--accent': '#2BB0ED',
            backgroundColor: '#EEF9FE',
        },
        '.elevio-box-success': {
            '--accent': 'var(--positiveDefault)',
            backgroundColor: 'var(--positiveSubtle)',
        },
        '.elevio-box-error': {
            '--accent': 'var(--negativeDefault)',
            backgroundColor: 'var(--negativeSubtle)',
        },
        '.elevio-box-warning': {
            '--accent': 'var(--warningDefault)',
            backgroundColor: 'var(--warningSubtle)',
        },
        img: {
            blockSize: 'auto',
            boxSizing: 'border-box',
            display: 'inline-block',
            marginBlockStart: '1.5em',
            marginBlockEnd: '1.5em',
            maxInlineSize: '100%',
            '&:first-of-type': {
                marginBlockStart: 0,
            },
            '&:last-of-type': {
                marginBlockEnd: 0,
            },
            '&.fr-dii': {
                display: 'inline-block',
                float: 'none',
                margin: '0 .25rem',
                maxInlineSize: 'calc(100% - 0.5rem)',
                verticalAlign: 'text-bottom',
            },
            '&.fr-fil': {
                float: 'left',
                marginInlineStart: 0,
            },
            '&.fr-fir': {
                float: 'right',
                marginInlineEnd: 0,
            },
        },
        'img.fr-dib, .fr-dib': {
            display: 'block',
            float: 'none',
            marginInlineStart: 'auto',
            marginInlineEnd: 'auto',
            '&.fr-fil': {
                marginInlineStart: 0,
            },
            '&.fr-fir': {
                marginInlineEnd: 0,
            },
        },
        'img.fr-bordered, .fr-bordered img': {
            borderColor: 'var(--border)',
            borderStyle: 'solid',
            borderWidth: '0.3125rem',
        },
        'img.fr-rounded, .fr-rounded img': {
            borderRadius: '0.625rem',
        },
        '.fr-dii, .fr-img-wrap': {
            display: 'inline-block',
        },
        '.fr-video': {
            display: 'block',
            inlineSize: '100%',
            marginBlockStart: '1.5em',
            marginBlockEnd: '1.5em',
            paddingBlockEnd: '56.25%',
            position: 'relative',
            '&:first-child': {
                marginBlockStart: 0,
            },
            '&:last-child': {
                marginBlockEnd: 0,
            },
            'iframe, video': {
                blockSize: '100% !important',
                inlineSize: '100% !important',
                left: 0,
                position: 'absolute',
                top: 0,
            },
        },
        code: {
            backgroundColor: 'var(--border)',
            borderRadius: toRem(3),
            color: 'var(--textDefault)',
            display: 'inline-block',
            fontFamily: 'var(--fontFamilyCode)',
            fontSize: '.875em',
            paddingInlineStart: toRem(4),
            paddingInlineEnd: toRem(4),
        },
        pre: {
            backgroundColor: 'var(--textStrong)',
            borderRadius: toRem(6),
            color: 'var(--accentTextStrong)',
            fontFamily: 'var(--fontFamilyCode)',
            fontSize: '.875em',
            overflow: 'auto',
            padding: `.75em 1.5em`,
            WebkitOverflowScrolling: 'touch',
            code: {
                backgroundColor: 'transparent',
                borderRadius: 0,
                color: 'inherit',
                display: 'block',
                fontSize: 'inherit',
                paddingInlineStart: 0,
                paddingInlineEnd: 0,
            },
        },
        table: {
            border: 'none',
            borderCollapse: 'collapse',
            '&.elev-table': {
                'td, th': {
                    border: '1px solid #DDD',
                    minInlineSize: '.5rem',
                    padding: '.5rem 1rem',
                    '&:empty': {
                        blockSize: '.125rem',
                    },
                    '&.fr-highlighted': {
                        border: '1px double var(--negativeDefault)',
                    },
                    '&.fr-thick': {
                        borderWidth: 2,
                    },
                },
                td: {
                    background: 'var(--backgroundContent)',
                },
                th: {
                    background: 'var(--backgroundWebsite)',
                },
                '&.fr-dashed-borders': {
                    'td, th': {
                        borderStyle: 'dashed',
                    },
                },
                '&.fr-alternate-rows': {
                    'tbody tr:nth-child(2n)': {
                        background: 'var(--backgroundWebsite)',
                    },
                },
            },
            '&:not(.elev-table)': {
                display: 'block',
                maxInlineSize: '100%',
                overflow: 'auto',
                td: {
                    borderTop: '1px solid #DDD',
                    padding: '.5rem',
                },
                th: {
                    borderBottom: '2px solid #DDD',
                    padding: '.5rem',
                },
            },
        },
        '.fr-emoticon': {
            backgroundRepeat: 'no-repeat',
            blockSize: '1em',
            display: 'inline-block',
            fontFamily: `"Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Segoe UI Symbol", "Android Emoji", EmojiSymbols`,
            fontWeight: 400,
            inlineSize: '1em',
            marginBlockStart: '-0.1875em',
            textIndent: -9999,
            verticalAlign: 'middle',
        },
        hr: {
            clear: 'both',
            marginBlockStart: 8,
            marginBlockEnd: 8,
            borderBlockStart: '.125rem solid var(--border)',
        },
        '.fr-inner': {
            display: 'block',
            fontSize: '.875rem',
            lineHeight: '1rem',
            textAlign: 'center',
        },
    },
    '.error-page': {
        '--accent': '(var--accentAccent)',
        '--backgroundWebsite': 'var(--accentBackground)',
        '--textStrong': 'var(--accentTextStrong)',
        '--textDefault': 'var(--accentTextDefault)',
        '--textSubtle': 'var(--accentTextSubtle)',
    },
});
