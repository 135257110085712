/** @jsx jsx */
/** @jsxRuntime classic */
import { Meta, SearchResults } from '@elevio/kb-kit/lib/components';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import { useTranslation } from '@elevio/kb-kit/lib/hooks';
import { useSearchResults, } from '@elevio/kb-kit/lib/hooks/useSearchResults';
import { css, jsx } from '@emotion/react';
import { Fragment, useContext, useEffect } from 'react';
import { ArticlesListItemPlaceholder, ContentHeader, Search, Skeleton, } from '../components';
import { toRem } from '../functions';
import { useAtBottomOfPage } from '../hooks';
import { Breadcrumbs, Content, Footer, Header, MainCentered, Nav, Page, } from '../layout';
import { textMediumCSS, textSmallCSS } from '../variables';
import { ArticlesListItem, articlesListCSS } from '../components/ArticlesList';
import { SearchResultContext } from '@elevio/kb-kit/lib/contexts';
// STYLES
const searchResultsCSS = css({
    borderBottomRightRadius: 'inherit',
    borderBottomLeftRadius: 'inherit',
});
const loadMoreButtonCSS = css([
    textSmallCSS,
    {
        borderBlockStart: 'thin solid var(--border)',
        color: 'var(--textSubtle)',
        display: 'block',
        fontWeight: 600,
        inlineSize: '100%',
        outline: 'none',
        paddingBlockStart: toRem(32),
        paddingBlockEnd: toRem(32),
        textAlign: 'center',
        transitionProperty: 'color',
        transitionDuration: '200ms',
        transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
        '&:hover': {
            color: 'var(--textDefault)',
        },
        '&:active': {
            color: 'var(--textStrong)',
        },
        '&:focus': {
            borderBottomRightRadius: 'inherit',
            borderBottomLeftRadius: 'inherit',
            boxShadow: `inset 0 0 0 ${toRem(4)} var(--accent)`,
        },
    },
]);
const noResultsMessageCSS = css([
    textMediumCSS,
    {
        marginBlockStart: toRem(16),
    },
]);
const noResultsMessageLinkCSS = css({
    color: 'var(--accent)',
    fontWeight: 600,
    outline: 'none',
    textDecoration: 'underline',
    transitionProperty: 'color',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    '&:hover': {
        opacity: 0.75,
    },
    '&:active': {
        opacity: 0.5,
    },
    '&:focus': {
        borderRadius: toRem(4),
        boxShadow: `0 0 0 ${toRem(2)} var(--accent)`,
        marginInlineStart: toRem(-2),
        marginInlineEnd: toRem(-2),
        paddingInlineStart: toRem(2),
        paddingInlineEnd: toRem(2),
    },
});
const noResultsMessageListCSS = css({
    display: 'grid',
    listStyle: 'disc',
    marginBlockStart: toRem(16),
    paddingInlineStart: toRem(32),
    rowGap: toRem(8),
});
// FUNCTIONS
export default function SearchPage() {
    // HOOKS
    const searchResults = useSearchResults();
    // RETURN
    return (<Page>
      <Nav hideSearch/>
      <Header>
        <Search large/>
      </Header>
      <MainCentered>
        <Breadcrumbs />
        <Content>
          {searchResults.results ? (<SearchPageResults {...searchResults}/>) : (<SearchPageLoading />)}
        </Content>
      </MainCentered>
      <Footer />
    </Page>);
}
function SearchPageLoading() {
    return (<Fragment>
      <ContentHeader hideActions={true} title={<Skeleton innerHeight={32} outerHeight={48} width={480}/>}/>
      <ArticlesListItemPlaceholder />
      <ArticlesListItemPlaceholder />
      <ArticlesListItemPlaceholder />
      <ArticlesListItemPlaceholder />
      <ArticlesListItemPlaceholder />
    </Fragment>);
}
function SearchPageResults({ hasMore, isFetching, loadMore, results, searchTerm, totalCount, }) {
    // HOOKS
    const nearBottomOfPage = useAtBottomOfPage({ tolerance: 0.5 });
    const { t } = useTranslation();
    // FUNCTIONS
    function buttonOnClick() {
        loadMore();
    }
    // VARIABLES
    const articleIDs = [];
    results === null || results === void 0 ? void 0 : results.map((result) => {
        articleIDs.push(result.id);
    });
    const totalCountLength = totalCount || 0;
    const notLoadedResultsCount = totalCount ? totalCount - articleIDs.length : 0;
    const placeholderItemsCount = notLoadedResultsCount < 10 ? notLoadedResultsCount : 10;
    const placeholderItems = [];
    for (let n = 0; n < placeholderItemsCount; n++) {
        placeholderItems.push(<ArticlesListItemPlaceholder key={n}/>);
    }
    const noResultsMessage = (<div css={noResultsMessageCSS}>
      <p>{t('search.noResults.message')}</p>
      <ul css={noResultsMessageListCSS}>
        <li>{t('search.noResults.suggestion1')}</li>
        <li>{t('search.noResults.suggestion2')}</li>
        {/* prettier-ignore */}
        <li>
          <Trans i18nKey="search.noResults.searchlink">
            Go to the <Meta.HomeLink css={noResultsMessageLinkCSS}>home page</Meta.HomeLink> and explore existing articles.
          </Trans>
        </li>
        <li>{t('search.noResults.suggestion4')}</li>
      </ul>
    </div>);
    // EFFECTS
    useEffect(() => {
        if (nearBottomOfPage && hasMore && !isFetching) {
            loadMore();
        }
    }, [nearBottomOfPage]);
    // RETURN
    return (<Fragment>
      <ContentHeader children={articleIDs.length === 0 && noResultsMessage} hideActions={true} title={t('search.resultsWithCount', {
            count: totalCountLength,
            searchTerm: searchTerm,
        })}/>

      {articleIDs.length > 0 && (<section aria-label={t('search.label')} className="kb-search-results" css={searchResultsCSS}>
          <SearchResultsList />
          {isFetching && placeholderItems}
          {hasMore && !isFetching && (<button className="kb-search-results__button" css={loadMoreButtonCSS} onClick={buttonOnClick}>
              {t('search.loadMore')}
            </button>)}
        </section>)}
    </Fragment>);
}
function SearchResultsList() {
    return (<ul className="kb-articles-list" css={articlesListCSS} dir="auto">
      <SearchResults.LoopResults>
        <SearchListItem />
      </SearchResults.LoopResults>
    </ul>);
}
function SearchListItem() {
    const { t } = useTranslation();
    const searchResult = useContext(SearchResultContext);
    if (!searchResult)
        return null;
    return <ArticlesListItem articleId={searchResult.id} useSearchLink/>;
}
