import Loader from './components/Loader';
import { ArticlePage, CategoryPage, ErrorPage, Error401Page, Error404Page, HomePage, SearchPage, } from './pages';
const config = {
    pages: {
        article: {
            component: ArticlePage,
        },
        home: {
            component: HomePage,
        },
        category: {
            component: CategoryPage,
        },
        search: {
            component: SearchPage,
        },
        error404: {
            component: Error404Page,
        },
        error401: {
            component: Error401Page,
        },
        error: {
            component: ErrorPage,
        },
    },
    pageLoadingIndicator: Loader,
};
export default config;
