import { css } from '@emotion/react';
import toRem from '../functions/toRem';
export const text01 = {
    fontSize: toRem(56),
    lineHeight: toRem(64),
};
export const text02 = {
    fontSize: toRem(48),
    lineHeight: toRem(56),
};
export const text03 = {
    fontSize: toRem(40),
    lineHeight: toRem(48),
};
export const text04 = {
    fontSize: toRem(36),
    lineHeight: toRem(44),
};
export const text05 = {
    fontSize: toRem(32),
    lineHeight: toRem(40),
};
export const text06 = {
    fontSize: toRem(28),
    lineHeight: toRem(36),
};
export const text07 = {
    fontSize: toRem(24),
    lineHeight: toRem(32),
};
export const text08 = {
    fontSize: toRem(22),
    lineHeight: toRem(32),
};
export const text09 = {
    fontSize: toRem(20),
    lineHeight: toRem(28),
};
export const text10 = {
    fontSize: toRem(18),
    lineHeight: toRem(28),
};
export const text11 = {
    fontSize: toRem(16),
    lineHeight: toRem(24),
};
export const text12 = {
    fontSize: toRem(14),
    lineHeight: toRem(20),
};
export const text13 = {
    fontSize: toRem(12),
    lineHeight: toRem(16),
};
export const errorTitleCSS = css({
    fontWeight: 700,
    letterSpacing: '-.01em',
    ...text05,
    '@media (min-width: 768px)': text03,
    '@media (min-width: 1024px)': text02,
    '@media (min-width: 1200px)': text01,
});
export const pageTitleCSS = css({
    fontWeight: 700,
    letterSpacing: '-.01em',
    ...text06,
    '@media (min-width: 768px)': text05,
    '@media (min-width: 1024px)': text04,
    '@media (min-width: 1200px)': text03,
});
export const heading1CSS = css({
    fontWeight: 700,
    letterSpacing: '-.01em',
    ...text07,
    '@media (min-width: 768px)': text06,
    '@media (min-width: 1024px)': text05,
    '@media (min-width: 1200px)': text04,
});
export const heading2CSS = css({
    fontWeight: 700,
    letterSpacing: '-.01em',
    ...text08,
    '@media (min-width: 768px)': text07,
    '@media (min-width: 1024px)': text06,
    '@media (min-width: 1200px)': text05,
});
export const heading3CSS = css({
    fontWeight: 700,
    letterSpacing: '-.01em',
    ...text09,
    '@media (min-width: 1024px)': text07,
    '@media (min-width: 1200px)': text06,
});
export const heading4CSS = css({
    fontWeight: 700,
    letterSpacing: '-.01em',
    ...text10,
    '@media (min-width: 1024px)': text09,
    '@media (min-width: 1200px)': text07,
});
export const heading5CSS = css({
    fontWeight: 700,
    letterSpacing: '-.01em',
    ...text11,
    '@media (min-width: 1024px)': text10,
    '@media (min-width: 1200px)': text09,
});
export const heading6CSS = css({
    fontWeight: 700,
    letterSpacing: '-.01em',
    ...text12,
    '@media (min-width: 1200px)': text11,
});
export const textLargeCSS = css({
    letterSpacing: '-.02em',
    ...text11,
    '@media (min-width: 1200px)': text10,
});
export const textMediumCSS = css({
    letterSpacing: '-.02em',
    ...text12,
    '@media (min-width: 1200px)': text11,
});
export const textSmallCSS = css({
    letterSpacing: '-.02em',
    ...text13,
    '@media (min-width: 1200px)': text12,
});
// ICONS
export const iconLargeCSS = css({
    blockSize: toRem(20),
    flexShrink: 0,
    inlineSize: toRem(20),
    padding: toRem(2),
    '@media (min-width: 1200px)': {
        blockSize: toRem(24),
        inlineSize: toRem(24),
    },
});
export const iconMediumCSS = css({
    blockSize: toRem(16),
    flexShrink: 0,
    inlineSize: toRem(16),
    padding: toRem(2),
    '@media (min-width: 1200px)': {
        blockSize: toRem(20),
        inlineSize: toRem(20),
    },
});
export const iconSmallCSS = css({
    blockSize: toRem(12),
    flexShrink: 0,
    inlineSize: toRem(12),
    padding: toRem(2),
    '@media (min-width: 1200px)': {
        blockSize: toRem(16),
        inlineSize: toRem(16),
    },
});
