/** @jsx jsx */
/** @jsxRuntime classic */
import { useTranslation } from '@elevio/kb-kit/lib/hooks';
import { css, jsx } from '@emotion/react';
import { useRef, useState } from 'react';
import { Icon, Search } from '../components';
import { toRem } from '../functions';
import { menuButtonCSS, menuIconCSS, menuLabelCSS } from '../layout';
// STYLES
const containerCSS = css({
    position: 'relative',
    '@media (min-width: 1024px)': {
        position: 'initial',
    },
});
const searchCSS = css({
    inlineSize: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 2,
    '@media (min-width: 1200px)': {
        minWidth: toRem(320),
    },
});
// FUNCTIONS
export default function MenuSearch() {
    // REFS
    const searchTriggerRef = useRef(null);
    // HOOKS
    const { t } = useTranslation();
    // STATES
    const [searchOpen, setSearchOpen] = useState(false);
    // FUNCTIONS
    function searchOnClose() {
        var _a;
        setSearchOpen(false);
        (_a = searchTriggerRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }
    function searchTriggerOnClick() {
        setSearchOpen(true);
    }
    // RETURN
    return (<li className="kb-nav__menu-list-item" css={containerCSS}>
      <button className="kb-nav__button" css={menuButtonCSS} onClick={searchTriggerOnClick} ref={searchTriggerRef}>
        <Icon name="search" className="kb-nav__button-icon" css={menuIconCSS}/>
        <span className="kb-nav__button-label" css={menuLabelCSS}>
          {t('nav.search')}
        </span>
      </button>
      <Search className="kb-nav__search" css={searchCSS} hidden={!searchOpen} onClose={searchOnClose}/>
    </li>);
}
