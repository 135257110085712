/** @jsx jsx */
/** @jsxRuntime classic */
import { css, jsx } from '@emotion/react';
import classNames from 'classnames';
import { toRem } from '../functions';
// STYLES
const mainCSS = css({
    gridArea: 'main',
    paddingBlockEnd: toRem(32),
    '@media (min-width: 768px)': {
        alignItems: 'start',
        alignContent: 'start',
        columnGap: toRem(16),
        display: 'grid',
        gridTemplateColumns: `repeat(12, minmax(0, ${toRem(64)}))`,
        justifyContent: 'center',
        paddingBlockEnd: toRem(48),
        paddingInlineStart: toRem(32),
        paddingInlineEnd: toRem(32),
        rowGap: toRem(40),
    },
    '@media (min-width: 1024px)': {
        columnGap: toRem(24),
        paddingBlockEnd: toRem(64),
        paddingInlineStart: toRem(40),
        paddingInlineEnd: toRem(40),
    },
    '@media (min-width: 1200px)': {
        columnGap: toRem(32),
        paddingBlockEnd: toRem(80),
        paddingInlineStart: toRem(0),
        paddingInlineEnd: toRem(0),
    },
});
const mainHomeCSS = css({
    gridTemplateColumns: `minmax(0, ${toRem(1120)})`,
    paddingBlockStart: toRem(32),
    '@media (min-width: 768px)': {
        backgroundImage: `linear-gradient(var(--accent) ${toRem(160)}, transparent ${toRem(160)})`,
        gridTemplateColumns: `minmax(0, ${toRem(1120)})`,
        paddingBlockStart: 0,
    },
});
const mainCenteredCSS = css({
    gridTemplateRows: 'auto auto',
    '@media (min-width: 768px)': {
        gridTemplateAreas: `'breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs'
                            'content     content     content     content     content     content     content     content     content     content     content     content    '`,
        paddingBlockStart: toRem(40),
    },
    '@media (min-width: 1024px)': {
        gridTemplateAreas: `'. breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs .'
                            '. content     content     content     content     content     content     content     content     content     content     .'`,
    },
    '@media (min-width: 1200px)': {
        gridTemplateAreas: `'. . breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs . .'
                            '. . content     content     content     content     content     content     content     content     . .'`,
    },
});
const mainArticleCSS = css({
    '@media (min-width: 768px)': {
        gridTemplateAreas: `'breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs'
                            'content     content     content     content     content     content     content     content     content     content     content     content    '
                            'aside       aside       aside       aside       aside       aside       aside       aside       aside       aside       aside       aside      '`,
        gridTemplateRows: 'auto auto auto',
        paddingBlockStart: toRem(40),
    },
    '@media (min-width: 1024px)': {
        gridTemplateAreas: `'breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs breadcrumbs'
                            'content     content     content     content     content     content     content     content     aside       aside       aside       aside      '`,
        gridTemplateRows: 'auto auto',
    },
});
const mainErrorCSS = css({
    backgroundColor: 'var(--background)',
    color: 'var(--textDefault)',
    gridTemplateColumns: `min(calc((100vw - ${toRem(352)}) / 2 + ${toRem(160)}), ${toRem(544)})`,
    paddingBlockStart: toRem(32),
    '@media (min-width: 768px)': {
        gridTemplateAreas: `'. content content content content content content content content content content .'`,
        paddingBlockStart: toRem(48),
    },
    '@media (min-width: 1024px)': {
        gridTemplateAreas: `'. . content content content content content content content content . .'`,
        paddingBlockStart: toRem(64),
    },
    '@media (min-width: 1200px)': {
        gridTemplateAreas: `'. . . content content content content content content . . .'`,
        paddingBlockStart: toRem(80),
    },
});
const mainErrorWrapperCSS = css({
    gridArea: 'content',
    paddingInlineStart: toRem(32),
    paddingInlineEnd: toRem(32),
    '@media (min-width: 768px)': {
        paddingInlineStart: 0,
        paddingInlineEnd: 0,
    },
});
// FUNCTIONS
function Main({ className, ...props }) {
    return (<main className={classNames('kb-main', className)} css={mainCSS} dir="auto" {...props}/>);
}
export function MainHome(props) {
    return (<Main className="kb-main--home" css={mainHomeCSS} dir="auto" {...props}/>);
}
export function MainCentered(props) {
    return (<Main className="kb-main--centered" css={mainCenteredCSS} dir="auto" {...props}/>);
}
export function MainArticle(props) {
    return (<Main className="kb-main--article" css={mainArticleCSS} dir="auto" {...props}/>);
}
export function MainError({ children, ...props }) {
    return (<Main className="kb-main-error" css={mainErrorCSS} dir="auto" {...props}>
      <div className="kn-main-error__wrapper" css={mainErrorWrapperCSS}>
        {children}
      </div>
    </Main>);
}
