import { useArticles, useCategory } from '@elevio/kb-kit/lib/hooks';
import React from 'react';
import { ArticlesList, ContentHeader, SubCategoriesList } from '../components';
import { Breadcrumbs, Content, Footer, MainCentered, Nav, Page, } from '../layout';
// FUNCTIONS
export default function CategoryPage() {
    // HOOKS
    const category = useCategory();
    const articles = useArticles();
    // VARIABLES
    const categoryTitle = category === null || category === void 0 ? void 0 : category.title;
    const categorySubCategoriesLength = (category === null || category === void 0 ? void 0 : category.subCategories.length) || 0;
    const categoryArticlesLength = (category === null || category === void 0 ? void 0 : category.articles.length) || 0;
    const subCategories = category === null || category === void 0 ? void 0 : category.subCategories;
    const articleIDs = [];
    articles.articles.map((article) => {
        articleIDs.push(article.id);
    });
    // RETURN
    return (<Page>
      <Nav />
      <MainCentered>
        <Breadcrumbs />
        <Content>
          <ContentHeader articlesCount={categoryArticlesLength} categoriesCount={categorySubCategoriesLength} title={categoryTitle}/>
          {subCategories && subCategories.length > 0 && (<SubCategoriesList subCategories={subCategories}/>)}
          {articleIDs.length > 0 && <ArticlesList articleIDs={articleIDs}/>}
        </Content>
      </MainCentered>
      <Footer />
    </Page>);
}
