/** @jsx jsx */
/** @jsxRuntime classic */
import { useLoading } from '@elevio/kb-kit/lib/hooks';
import { css, jsx } from '@emotion/react';
import { toRem } from '../functions';
// STYLES
const loadingBarCSS = css({
    backgroundColor: 'var(--accentTextSubtle)',
    blockSize: toRem(6),
    boxShadow: `0 0 ${toRem(16)} var(--accentBackgroundDropdown)`,
    inlineSize: '100%',
    left: 0,
    position: 'fixed',
    top: 0,
    zIndex: 3,
});
// FUNCTIONS
export default function LoadingBar() {
    // HOOKS
    const { percent } = useLoading();
    // VARIABLES
    const loadingStyle = {
        transform: `translate3d(-${100 - percent}%, 0px, 0px)`,
    };
    // RETURN
    return (<div className="kb-loading-bar" css={loadingBarCSS} style={loadingStyle}/>);
}
