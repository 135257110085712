/** @jsx jsx */
/** @jsxRuntime classic */
import { Article } from '@elevio/kb-kit/lib/components';
import { css, jsx } from '@emotion/react';
import { useState } from 'react';
import { Icon } from '../components';
import { toRem, uniqueId } from '../functions';
import { heading5CSS, heading6CSS, textSmallCSS } from '../variables';
// STYLES
const categoryCSS = css({
    display: 'grid',
    rowGap: toRem(12),
});
const categoryTitle = css([
    heading5CSS,
    {
        color: 'var(--textStrong)',
    },
]);
const articlesListCSS = css({
    display: 'grid',
    rowGap: toRem(12),
    '&[hidden]': {
        display: 'none',
    },
});
const articlesListItemCSS = css({
    display: 'flex',
});
const articlesLinkCSS = css([
    textSmallCSS,
    {
        color: 'var(--textSubtle)',
        outline: 'none',
        transitionProperty: 'color',
        transitionDuration: '200ms',
        transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
        '&:hover': {
            color: 'var(--textDefault)',
            textDecoration: 'underline',
        },
        '&:active': {
            color: 'var(--textStrong)',
        },
        '&:focus': {
            borderRadius: toRem(4),
            boxShadow: `0 0 0 ${toRem(2)} var(--accent)`,
            marginInlineStart: toRem(-2),
            marginInlineEnd: toRem(-2),
            paddingInlineStart: toRem(2),
            paddingInlineEnd: toRem(2),
        },
        '&[aria-current="page"]': {
            color: 'var(--accent)',
            fontWeight: 600,
        },
    },
]);
const subCategoryCSS = css({
    display: 'grid',
    paddingBlockStart: toRem(4),
    rowGap: toRem(12),
});
const subCategoryTitleCSS = css([
    heading6CSS,
    {
        color: 'var(--textDefault)',
        display: 'flex',
        outline: 'none',
        transitionProperty: 'opacity',
        transitionDuration: '200ms',
        transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
        '&:hover': {
            opacity: 0.75,
        },
        '&:active': {
            opacity: 0.5,
        },
        '&:focus': {
            borderRadius: toRem(4),
            boxShadow: `0 0 0 ${toRem(2)} var(--accent)`,
            marginInlineStart: toRem(-2),
            marginInlineEnd: toRem(-4),
            paddingInlineStart: toRem(2),
            paddingInlineEnd: toRem(4),
        },
    },
]);
const subCategoryIconCSS = css({
    blockSize: toRem(16),
    fill: 'var(--textSubtle)',
    flexShrink: 0,
    inlineSize: toRem(16),
    marginBlockStart: toRem(4),
    marginInlineEnd: toRem(8),
});
const subCategoryArticlesListCSS = css([
    articlesListCSS,
    {
        paddingInlineStart: toRem(24),
    },
]);
// FUNCTIONS
export function SidebarList({ children, title }) {
    return (<section className="kb-sidebar" css={categoryCSS}>
      <h3 className="kb-sidebar__title" css={categoryTitle}>
        {title}
      </h3>
      {children && (<ul className="kb-sidebar__list" css={articlesListCSS}>
          {children}
        </ul>)}
    </section>);
}
export function SidebarListArticle({ id, title }) {
    return (<li className="kb-sidebar__article" css={articlesListItemCSS}>
      <Article.Link articleId={id} children={title} className="kb-sidebar__article-link" css={articlesLinkCSS}/>
    </li>);
}
export function SidebarListSubCategory({ children, title, }) {
    // STATE
    const [expanded, setExpanded] = useState(false);
    const [titleID] = useState(uniqueId('title-'));
    const [regionID] = useState(uniqueId('region-'));
    // FUNCTIONS
    function subCategoryOnClick() {
        setExpanded(!expanded);
    }
    // RETURN
    return (<li className="kb-sidebar__subcategory" css={subCategoryCSS}>
      <h4 className="kb-sidebar__subcategory-title">
        <button aria-controls={regionID} className="kb-sidebar__subcategory-button" css={subCategoryTitleCSS} id={titleID} onClick={subCategoryOnClick}>
          <Icon name={expanded ? 'minus' : 'plus'} className="kb-sidebar__subcategory-button-icon" css={subCategoryIconCSS}/>
          {title}
        </button>
      </h4>
      {children && (<ul aria-label={titleID} children={children} className="kb-sidebar__subcategory-articles" css={subCategoryArticlesListCSS} hidden={!expanded} id={regionID} role="region"/>)}
    </li>);
}
