import InnerHtml from '@elevio/kb-kit/lib/components/InnerHtml';
import { Helmet } from '@elevio/kb-kit/lib/components/meta';
import React, { Fragment } from 'react';
import { LoadingBar } from '../layout';
import { themeOptions } from '../variables/themeOptions';
import { Alert } from '../components';
import { useNotifications } from '@elevio/kb-kit/lib/hooks';
// FUNCTIONS
export default function Page({ children }) {
    const { notification, clearNotification } = useNotifications();
    return (<Fragment>
      <LoadingBar />

      {notification && (<Alert message={notification.message} tone={getNotificationTone(notification.type)} onClear={clearNotification}/>)}

      {children}
      {themeOptions.customCSS && (<Helmet>
          <style>{`${themeOptions.customCSS}`}</style>
        </Helmet>)}
      {themeOptions.customJS && (<InnerHtml html={`<script>${themeOptions.customJS}</script>`}/>)}
    </Fragment>);
}
const getNotificationTone = (type) => {
    switch (type) {
        case 'success':
            return 'positive';
        case 'error':
        default:
            return 'negative';
    }
};
