/** @jsx jsx */
/** @jsxRuntime classic */
import { Auth, Meta } from '@elevio/kb-kit/lib/components';
import InnerHtml from '@elevio/kb-kit/lib/components/InnerHtml';
import { useAuth, useLanguages, useTranslation, } from '@elevio/kb-kit/lib/hooks';
import { getCompanyUrl } from '@elevio/kb-kit/lib/utils/globals';
import { css, jsx } from '@emotion/react';
import React, { Fragment, useEffect, useState, } from 'react';
import { Icon, LanguagePicker, MenuSearch } from '../components';
import { toRem } from '../functions';
import { heading5CSS } from '../variables';
import { themeOptions } from '../variables/themeOptions';
// STYLES
const navCSS = css({
    alignItems: 'center',
    backgroundColor: 'var(--accentBackground)',
    color: 'var(--accentTextStrong)',
    display: 'grid',
    fill: 'var(--accentTextStrong)',
    gridTemplateAreas: `'details . menuTrigger'
                        'menu menu menu'`,
    gridTemplateColumns: 'max-content auto max-content',
    gridTemplateRows: 'auto auto',
    padding: `${toRem(20)} ${toRem(24)}`,
    position: 'sticky',
    top: 0,
    transform: 'translate3d(0,0,0)',
    transitionProperty: 'box-shadow',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    zIndex: 3,
    '@media (min-width: 768px)': {
        padding: `${toRem(24)} ${toRem(32)}`,
    },
    '@media (min-width: 1024px)': {
        gridTemplateAreas: `'details . menu'`,
        gridTemplateRows: 'auto',
        padding: `${toRem(32)} ${toRem(40)}`,
    },
    '@media (min-width: 1200px)': {
        gridTemplateAreas: `'. details . menu .'`,
        gridTemplateColumns: `calc((100vw - ${toRem(1120)}) / 2) max-content auto max-content calc((100vw - ${toRem(1120)}) / 2)`,
        padding: `${toRem(40)} 0}`,
    },
    '&[data-sticky="true"]': {
        boxShadow: `0 0 ${toRem(32)} rgba(0,0,0,.32)`,
    },
});
const detailsCSS = css({
    alignItems: 'inherit',
    display: 'grid',
    gridAutoFlow: 'column',
    gap: toRem(12),
    gridArea: 'details',
    '@media (min-width: 1024px)': {
        gap: toRem(16),
    },
});
const separatorCSS = css({
    borderInlineStart: 'thin solid var(--accentTextStrong)',
    blockSize: toRem(24),
});
const homeLinkCSS = css({
    borderRadius: toRem(6),
    margin: toRem(-8),
    outline: 'none',
    padding: toRem(8),
    position: 'relative',
    transitionProperty: 'opacity',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    '&:hover': {
        opacity: 0.75,
    },
    '&:active': {
        opacity: 0.5,
    },
    '&:focus': {
        boxShadow: `0 0 0 ${toRem(2)} var(--accentAccent)`,
    },
});
const logoCSS = css({
    blockSize: toRem(24),
    display: 'block',
    inlineSize: 'auto',
    '@media (min-width: 1024px)': {
        blockSize: toRem(32),
    },
});
const menuTriggerCSS = css({
    borderRadius: toRem(6),
    gridArea: 'menuTrigger',
    margin: toRem(-4),
    outline: 'none',
    padding: toRem(4),
    transitionProperty: 'opacity',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    '@media (min-width: 1024px)': {
        display: 'none',
        gridArea: 'none',
    },
    '&:hover': {
        opacity: 0.75,
    },
    '&:active': {
        opacity: 0.5,
    },
    '&:focus': {
        boxShadow: `0 0 0 ${toRem(2)} var(--accentAccent)`,
    },
});
const menuTriggerIconCSS = css({
    blockSize: toRem(20),
    inlineSize: toRem(20),
    padding: toRem(2),
});
const menuCSS = css([
    {
        fontSize: toRem(14),
        fontWeight: 600,
        gridArea: 'menu',
        letterSpacing: '-.02em',
        lineHeight: toRem(24),
        paddingBlockStart: toRem(24),
        position: 'relative',
        '@media (min-width: 1024px)': {
            paddingBlockStart: 0,
        },
    },
]);
const menuListCSS = css([
    {
        '@media (min-width: 1024px)': {
            display: 'grid',
            gap: toRem(8),
            gridAutoFlow: 'column',
        },
    },
]);
export const menuButtonCSS = css({
    borderRadius: toRem(6),
    boxSizing: 'border-box',
    display: 'flex',
    inlineSize: '100%',
    padding: toRem(12),
    outline: 'none',
    transitionProperty: 'opacity',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    '&:hover': {
        opacity: 0.75,
    },
    '&:active': {
        opacity: 0.5,
    },
    '&:focus': {
        boxShadow: `0 0 0 ${toRem(2)} var(--accentAccent)`,
    },
    '&[aria-expanded="true"]': {
        backgroundColor: 'var(--accentBackgroundDropdown)',
        boxShadow: `0 ${toRem(1)} ${toRem(2)} rgba(0,0,0,.08), 0 ${toRem(4)} ${toRem(16)} rgba(0,0,0,.08)`,
        opacity: 1,
    },
    '@media (min-width: 1024px)': {
        padding: toRem(8),
    },
});
export const menuIconCSS = css({
    blockSize: toRem(16),
    inlineSize: toRem(16),
    padding: toRem(4),
});
export const menuLabelCSS = css({
    flexGrow: 1,
    paddingInlineStart: toRem(8),
    paddingInlineEnd: toRem(8),
});
// FUNCTIONS
function LoginLogoutLinkChildren({ icon, label, }) {
    return (<>
      <Icon name={icon} className="kb-nav__button-icon" css={menuIconCSS}/>
      <span className="kb-nav__button-label" css={menuLabelCSS}>
        {label}
      </span>
    </>);
}
export default function Nav({ hideSearch, ...props }) {
    // HOOKS
    const { isSSOEnabled, isAgentLoginEnabled } = useAuth();
    const { supportedLanguages } = useLanguages();
    const { t } = useTranslation();
    // STATES
    const [menu, setMenu] = useState(window.innerWidth >= 1024);
    const [sticky, setSticky] = useState(false);
    // FUNCTIONS
    function menuTriggerOnClick() {
        setMenu(!menu);
    }
    function windowOnResize() {
        if (window.innerWidth >= 1024) {
            setMenu(true);
        }
    }
    function windowOnScroll() {
        if (document.scrollingElement) {
            setSticky(document.scrollingElement.scrollTop > 0);
        }
    }
    // VARIABLES
    const closeMenuLabel = t('nav.closeMenu');
    const openMenuLabel = t('nav.openMenu');
    const toggleMenuLabel = menu ? closeMenuLabel : openMenuLabel;
    const companyUrl = getCompanyUrl();
    const companyUrlClean = companyUrl && companyUrl.replace(/^((\w+:|^)\/\/)?(www.)?/, '');
    const hideMenu = hideSearch &&
        !companyUrl &&
        !isSSOEnabled &&
        supportedLanguages.length <= 1;
    const { customHeaderHtml, imageUrl } = themeOptions;
    // EFFECTS
    useEffect(() => {
        window.addEventListener('resize', windowOnResize);
        window.addEventListener('scroll', windowOnScroll);
        return () => {
            window.removeEventListener('resize', windowOnResize);
            window.removeEventListener('scroll', windowOnScroll);
        };
    }, []);
    // RETURN
    return (<Fragment>
      {customHeaderHtml ? (<InnerHtml className="kb-custom-header" html={customHeaderHtml}/>) : (<header className="kb-nav" css={navCSS} data-sticky={sticky} role="banner" dir="auto" {...props}>
          <div className="kb-nav__details" css={detailsCSS}>
            {imageUrl && (<Fragment>
                <Meta.HomeLink aria-label={t('nav.home')} className="kb-nav__details-logo-link" css={homeLinkCSS}>
                  <img className="kb-nav__details-logo-image" css={logoCSS} src={imageUrl}/>
                </Meta.HomeLink>
                <hr aria-orientation="vertical" className="kb-nav__details-separator" css={separatorCSS}/>
              </Fragment>)}
            {t('nav.title', '') && (<Meta.HomeLink aria-label={t('nav.home')} className="kb-nav__details-title" css={heading5CSS}>
                {t('nav.title')}
              </Meta.HomeLink>)}
          </div>
          {!hideMenu && (<>
              <button aria-controls="menu" aria-expanded={menu} aria-haspopup="true" aria-label={toggleMenuLabel} className="kb-nav__menu-trigger" css={menuTriggerCSS} id="menu-trigger" onClick={menuTriggerOnClick}>
                <Icon name={menu ? 'x' : 'menu'} className="kb-nav__menu-trigger-icon" css={menuTriggerIconCSS}/>
              </button>
              <nav aria-label={t('nav.menuLabel')} className="kb-nav__menu" css={menuCSS} hidden={!menu} id="menu">
                <ul className="kb-nav__menu-list" css={menuListCSS}>
                  {!hideSearch && <MenuSearch />}
                  {companyUrl && (<li className="kb-nav__menu-list-item">
                      <a className="kb-nav__button" css={menuButtonCSS} href={companyUrl}>
                        <Icon name="external-link" className="kb-nav__button-icon" css={menuIconCSS}/>
                        <span className="kb-nav__button-label" css={menuLabelCSS}>
                          {companyUrlClean}
                        </span>
                      </a>
                    </li>)}
                  {isSSOEnabled && (<li className="kb-nav__menu-list-item">
                      <Auth.LoginLogoutLink className="kb-nav__button" css={menuButtonCSS} loginChildren={LoginLogoutLinkChildren({
                        icon: 'log-in',
                        label: t('common.signIn'),
                    })} logoutChildren={LoginLogoutLinkChildren({
                        icon: 'log-out',
                        label: t('common.signOut'),
                    })}/>
                    </li>)}
                  {supportedLanguages.length > 1 && <LanguagePicker />}
                </ul>
              </nav>
            </>)}
        </header>)}
    </Fragment>);
}
