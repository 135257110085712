/** @jsx jsx */
/** @jsxRuntime classic */
import { Article, SearchResults } from '@elevio/kb-kit/lib/components';
import { useArticleFragment, useCategory, useTranslation, } from '@elevio/kb-kit/lib/hooks';
import { css, jsx } from '@emotion/react';
import { Icon, Skeleton } from '../components';
import { toRem } from '../functions';
import { heading5CSS, textMediumCSS, textSmallCSS } from '../variables';
// STYLES
export const articlesListCSS = css({
    '&:last-child': {
        borderBottomRightRadius: 'inherit',
        borderBottomLeftRadius: 'inherit',
    },
});
const articlesListItemCSS = css({
    borderBlockStart: 'thin solid var(--border)',
    display: 'block',
    '&:last-child': {
        borderBottomRightRadius: 'inherit',
        borderBottomLeftRadius: 'inherit',
    },
});
const articlesListItemLinkCSS = css({
    display: 'block',
    outline: 'none',
    padding: `${toRem(24)} ${toRem(32)}`,
    transitionProperty: 'opacity',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    '@media (min-width: 1200px)': {
        padding: `${toRem(32)} ${toRem(40)}`,
    },
    '&:hover': {
        opacity: 0.75,
    },
    '&:active': {
        opacity: 0.5,
    },
    '&:focus': {
        borderBottomRightRadius: 'inherit',
        borderBottomLeftRadius: 'inherit',
        boxShadow: `inset 0 0 0 ${toRem(4)} var(--accent)`,
    },
});
const articlesListItemTitleCSS = css([
    heading5CSS,
    {
        color: 'var(--accent)',
    },
]);
const articlesListItemInfoCSS = css([
    textSmallCSS,
    {
        alignItems: 'center',
        color: 'var(--textSubtle)',
        display: 'flex',
        fill: 'var(--textSubtle)',
        marginBlockStart: toRem(4),
    },
]);
const articlesListItemInfoIconCSS = css({
    blockSize: toRem(14),
    flexShrink: 0,
    inlineSize: toRem(14),
    marginInlineEnd: toRem(8),
});
const articlesListItemSummaryCSS = css([
    textMediumCSS,
    {
        color: 'var(--textDefault)',
        marginBlockStart: toRem(4),
    },
]);
// FUNCTION
export default function ArticlesList({ articleIDs }) {
    return (<ul className="kb-articles-list" css={articlesListCSS} dir="auto">
      {articleIDs.map((articleId, index) => {
            return (<ArticlesListItem key={index} articleId={articleId} hideCategory={true}/>);
        })}
    </ul>);
}
export function ArticlesListItem({ articleId, hideCategory, useSearchLink = false, }) {
    // HOOKS
    const article = useArticleFragment({ articleId });
    const { t } = useTranslation();
    // RETURN
    if (!article)
        return null;
    const LinkComponent = useSearchLink
        ? SearchResults.ArticleLink
        : Article.Link;
    const { categoryId, lastPublishedAtLocal, summary, title } = article;
    return (<li className="kb-articles-list__item" css={articlesListItemCSS}>
      <LinkComponent articleId={articleId} className="kb-articles-list__link" css={articlesListItemLinkCSS}>
        <h2 className="kb-articles-list__title" css={articlesListItemTitleCSS}>
          {title}
        </h2>
        {!hideCategory && <ArticlesListItemCategory categoryId={categoryId}/>}
        {summary && (<p className="kb-articles-list__summary" css={articlesListItemSummaryCSS}>
            {summary}
          </p>)}
        <p className="kb-articles-list__info" css={articlesListItemInfoCSS}>
          <Icon name="edit" className="kb-articles-list__info-icon" css={articlesListItemInfoIconCSS}/>
          {t('common.lastUpdatedOn')} {lastPublishedAtLocal}
        </p>
      </LinkComponent>
    </li>);
}
function ArticlesListItemCategory({ categoryId, }) {
    // HOOKS
    const category = useCategory({ categoryId });
    // RETURN
    if (category) {
        const { title } = category;
        return (<p className="kb-articles-list__info" css={articlesListItemInfoCSS}>
        <Icon name="folder" className="kb-articles-list__info-icon" css={articlesListItemInfoIconCSS}/>
        {title}
      </p>);
    }
    else {
        return null;
    }
}
export function ArticlesListItemPlaceholder() {
    return (<div className="kb-articles-list__item placeholder" css={articlesListItemCSS} role="presentation">
      <div className="kb-articles-list__link placeholder" css={articlesListItemLinkCSS}>
        <Skeleton innerHeight={16} outerHeight={28} width={400}/>
        <Skeleton innerHeight={12} outerHeight={24} width={200}/>
        <Skeleton innerHeight={14} outerHeight={28}/>
        <Skeleton innerHeight={12} outerHeight={24} width={160}/>
      </div>
    </div>);
}
