/** @jsx jsx */
/** @jsxRuntime classic */
import { Article, Category } from '@elevio/kb-kit/lib/components';
import { useArticleFragment, useCategory, useTranslation, } from '@elevio/kb-kit/lib/hooks';
import { css, jsx } from '@emotion/react';
import { Icon } from '../components';
import { toRem } from '../functions';
import { heading5CSS, iconSmallCSS, textSmallCSS } from '../variables';
// STYLES
const subCategoriesCSS = css({
    borderBlockStart: 'thin solid var(--border)',
    padding: toRem(32),
    '@media (min-width: 768px)': {
        padding: toRem(40),
    },
    '@media (min-width: 1200px)': {
        padding: toRem(48),
    },
});
const subCategoriesListCSS = css({
    alignItems: 'start',
    display: 'grid',
    rowGap: toRem(32),
    '@media (min-width: 768px)': {
        columnGap: toRem(32),
        gridTemplateColumns: '1fr 1fr',
    },
});
const subCategoriesListItemCSS = css({
    display: 'grid',
    rowGap: toRem(12),
});
const subCategoryTitleCSS = css({
    color: 'var(--accent)',
    outline: 'none',
    transitionProperty: 'opacity',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    '&:hover': {
        opacity: 0.75,
    },
    '&:active': {
        opacity: 0.5,
    },
    '&:focus': {
        borderRadius: toRem(4),
        boxShadow: `0 0 0 ${toRem(2)} var(--accent)`,
        marginInlineStart: toRem(-4),
        marginInlineEnd: toRem(-4),
        paddingInlineStart: toRem(4),
        paddingInlineEnd: toRem(4),
    },
});
const articlesListCSS = css({
    display: 'grid',
    rowGap: toRem(12),
});
const articlesListItemCSS = css({
    display: 'flex',
});
const articleLinkCSS = css([
    textSmallCSS,
    {
        color: 'var(--textSubtle)',
        fontWeight: 600,
        outline: 'none',
        transitionProperty: 'color',
        transitionDuration: '200ms',
        transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
        '&:hover': {
            color: 'var(--textDefault)',
            textDecoration: 'underline',
        },
        '&:active': {
            color: 'var(--textStrong)',
        },
        '&:focus': {
            borderRadius: toRem(4),
            boxShadow: `0 0 0 ${toRem(2)} var(--accent)`,
            marginInlineStart: toRem(-4),
            paddingInlineStart: toRem(4),
        },
    },
]);
const moreArticleLinkCSS = css([
    textSmallCSS,
    {
        color: 'var(--textSubtle)',
        fontWeight: 600,
        outline: 'none',
        transitionProperty: 'color',
        transitionDuration: '200ms',
        transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
        '&:hover': {
            color: 'var(--textDefault)',
            textDecoration: 'underline',
        },
        '&:active': {
            color: 'var(--textStrong)',
        },
        '&:focus': {
            borderRadius: toRem(4),
            boxShadow: `0 0 0 ${toRem(2)} var(--accent)`,
            marginInlineStart: toRem(-4),
            marginInlineEnd: toRem(-4),
            paddingInlineStart: toRem(4),
            paddingInlineEnd: toRem(4),
        },
    },
]);
const articlesIconCSS = css([
    iconSmallCSS,
    {
        display: 'inline-block',
        fill: 'currentColor',
        verticalAlign: 'top',
    },
]);
// FUNCTIONS
export default function SubCategoriesList({ subCategories, }) {
    return (<section className="kb-subcategories" css={subCategoriesCSS}>
      <ul className="kb-subcategories__list" css={subCategoriesListCSS}>
        {subCategories.map((categoryId, index) => {
            return <SubCategoriesListItem categoryId={categoryId} key={index}/>;
        })}
      </ul>
    </section>);
}
function SubCategoriesListItem({ categoryId }) {
    // HOOKS
    const category = useCategory({ categoryId });
    const { t } = useTranslation();
    // RETURN
    if (category) {
        // VARIABLES
        const { articles, title } = category;
        const articlesTrimmed = articles.slice(0, 5);
        const moreArticlesLength = articles.length - articlesTrimmed.length;
        // RETURN
        return (<li className="kb-subcategories__list-item" css={subCategoriesListItemCSS}>
        <h2 className="kb-subcategories__title" css={heading5CSS}>
          <Category.Link categoryId={categoryId} className="kb-subcategories__link" css={subCategoryTitleCSS}>
            {title}
          </Category.Link>
        </h2>
        {articlesTrimmed.length > 0 && (<ul className="kb-subcategories__articles-list" css={articlesListCSS}>
            {articlesTrimmed.map((articleId, index) => {
                    return <ArticleItem articleId={articleId} key={index}/>;
                })}
            {moreArticlesLength > 0 && (<li className="kb-subcategories__articles-more" css={articlesListItemCSS}>
                <Category.Link categoryId={categoryId} className="kb-subcategories__articles-more-button" css={moreArticleLinkCSS}>
                  {t('common.moreArticleWithCount', {
                        count: moreArticlesLength,
                    })}
                </Category.Link>
              </li>)}
          </ul>)}
      </li>);
    }
    else {
        return null;
    }
}
function ArticleItem({ articleId }) {
    // HOOKS
    const article = useArticleFragment({ articleId });
    // RETURN
    if (article) {
        const { title } = article;
        return (<li className="kb-subcategories__articles-list-item" css={articlesListItemCSS}>
        <Article.Link className="kb-subcategories__articles-link" articleId={articleId} css={articleLinkCSS}>
          {title}
          <Icon name="chevron-right" className="kb-subcategories__articles-link-icon" css={articlesIconCSS}/>
        </Article.Link>
      </li>);
    }
    else {
        return null;
    }
}
