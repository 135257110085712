/** @jsx jsx */
/** @jsxRuntime classic */
import { useTranslation } from '@elevio/kb-kit/lib/hooks';
import { css, jsx } from '@emotion/react';
import { LoadingSpinner } from '../components';
import { toRem } from '../functions';
import { textMediumCSS } from '../variables';
// STYLES
const wrapperCSS = css({
    alignItems: 'center',
    color: 'var(--textDefault)',
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
});
const textCSS = css([
    textMediumCSS,
    {
        marginBlockStart: toRem(8),
    },
]);
// FUNCTION
export default function Loader() {
    // HOOKS
    const { t } = useTranslation();
    // RETURN
    return (<div className="kb-loader" css={wrapperCSS}>
      <LoadingSpinner className="kb-loader__spinner"/>
      <p className="kb-loader__label" css={textCSS}>
        {t('common.loading')}
      </p>
    </div>);
}
