/** @jsx jsx */
/** @jsxRuntime classic */
import { Meta } from '@elevio/kb-kit/lib/components';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import { useTranslation } from '@elevio/kb-kit/lib/hooks';
import { css, jsx } from '@emotion/react';
import { Search } from '../components';
import { toRem } from '../functions';
import { Footer, MainError, Nav, Page } from '../layout';
import { errorTitleCSS, textLargeCSS } from '../variables';
// STYLES
const titleCSS = css([
    errorTitleCSS,
    {
        color: 'var(--textStrong)',
        marginBlockEnd: toRem(32),
        textAlign: 'center',
    },
]);
const messageCSS = css([
    textLargeCSS,
    {
        color: 'var(--textDefault)',
        marginBlockEnd: toRem(32),
        textAlign: 'center',
    },
]);
const linkCSS = css({
    color: 'var(--accent)',
    fontWeight: 600,
    outline: 'none',
    textDecoration: 'underline',
    transitionProperty: 'opacity',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    '&:hover': {
        opacity: 0.75,
    },
    '&:active': {
        opacity: 0.5,
    },
    '&:focus': {
        borderRadius: toRem(4),
        boxShadow: `0 0 0 ${toRem(2)} var(--accent)`,
        marginInlineStart: toRem(-2),
        marginInlineEnd: toRem(-2),
        paddingInlineStart: toRem(2),
        paddingInlineEnd: toRem(2),
    },
});
// FUNCTIONS
function ErrorPageTemplate({ title, message, suggestion, }) {
    return (<Page>
      <Nav hideSearch/>
      <MainError>
        <h1 className="kb-error__title" css={titleCSS}>
          {title}
        </h1>
        <p className="kb-error__message" css={messageCSS}>
          {message}
        </p>
        <p className="kb-error__suggestion" css={messageCSS}>
          {suggestion}
        </p>
        <Search large/>
      </MainError>
      <Footer />
    </Page>);
}
export default function ErrorPage() {
    // HOOKS
    const { t } = useTranslation();
    // RETURN
    return (<ErrorPageTemplate title={t('error.title')} message={t('error.message')} suggestion={<Trans i18nKey="error.suggestion">
          You could try to sign in, go to the{' '}
          <Meta.HomeLink css={linkCSS}>home page</Meta.HomeLink> or search
          below.
        </Trans>}/>);
}
export function Error401Page() {
    // HOOKS
    const { t } = useTranslation();
    // RETURN
    return (<ErrorPageTemplate title={t('error401.title')} message={t('error401.message')} suggestion={t('error401.suggestions')}/>);
}
export function Error404Page() {
    // HOOKS
    const { t } = useTranslation();
    const suggestion = (<Trans i18nKey="error404.suggestions">
      Try searching for the article below, or go to the{' '}
      <Meta.HomeLink css={linkCSS}>home page</Meta.HomeLink>.
    </Trans>);
    // RETURN
    return (<ErrorPageTemplate title={t('error404.title')} message={t('error404.message')} suggestion={suggestion}/>);
}
