/** @jsx jsx */
/** @jsxRuntime classic */
import { useTranslation } from '@elevio/kb-kit/lib/hooks';
import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import { Icon } from '../components';
import { toRem } from '../functions';
import { iconSmallCSS, pageTitleCSS, textSmallCSS } from '../variables';
// STYLES
const headerCSS = css({
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    position: 'relative',
});
const actionsListCSS = css({
    columnGap: toRem(8),
    display: 'grid',
    gridAutoFlow: 'column',
    position: 'absolute',
    right: toRem(16),
    top: toRem(16),
    '@media (min-width: 768px)': {
        right: toRem(24),
        top: toRem(24),
    },
});
const actionsListItemCSS = css({
    position: 'relative',
});
const actionsButtonCSS = css({
    borderRadius: toRem(4),
    display: 'block',
    fill: 'var(--textSubtle)',
    outline: 'none',
    transitionProperty: 'fill, opacity',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    '&:hover': {
        fill: 'var(--textDefault)',
    },
    '&:active': {
        fill: 'var(--textStrong)',
    },
    '&:focus': {
        boxShadow: `0 0 0 ${toRem(2)} var(--accent)`,
    },
});
const actionsIconCSS = css({
    blockSize: toRem(16),
    inlineSize: toRem(16),
    padding: toRem(8),
});
const actionsTooltipCSS = css({
    backgroundColor: 'var(--textStrong)',
    borderRadius: toRem(4),
    bottom: '100%',
    color: 'var(--backgroundWebsite)',
    fontSize: toRem(12),
    fontWeight: 600,
    left: '50%',
    letterSpacing: '-.02em',
    lineHeight: toRem(16),
    padding: `${toRem(4)} ${toRem(8)}`,
    position: 'absolute',
    transform: `translate3d(-50%, ${toRem(-8)}, 0)`,
    whiteSpace: 'nowrap',
    '&::before': {
        backgroundColor: 'inherit',
        blockSize: toRem(6),
        borderBottomRightRadius: toRem(2),
        content: `''`,
        display: 'block',
        inlineSize: toRem(6),
        left: '50%',
        pointerEvents: 'none',
        position: 'absolute',
        top: '100%',
        transform: 'translate3d(-50%, -50%, 0) rotate(45deg)',
    },
});
const featuredImageCSS = css({
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    position: 'relative',
    overflow: 'hidden',
});
const imageCSS = css({
    blockSize: 'auto',
    display: 'block',
    inlineSize: '100%',
});
const contentCSS = css({
    padding: toRem(32),
    position: 'relative',
    '@media (min-width: 768px)': {
        padding: toRem(40),
    },
    '@media (min-width: 1200px)': {
        padding: toRem(48),
    },
});
const titleCSS = css([
    pageTitleCSS,
    {
        color: 'var(--textStrong)',
    },
]);
const detailsListCSS = css([
    textSmallCSS,
    {
        color: 'var(--textSubtle)',
        display: 'grid',
        fill: 'var(--textSubtle)',
        marginBlockStart: toRem(16),
        rowGap: toRem(8),
    },
]);
const detailsListItemCSS = css({
    display: 'flex',
});
const detailsListItemBannerCSS = css({
    backgroundColor: 'var(--negativeSubtle)',
    borderInlineStart: `${toRem(3)} solid var(--negativeDefault)`,
    borderRadius: toRem(3),
    color: 'var(--negativeDefault)',
    fill: 'var(--negativeDefault)',
    fontWeight: 600,
    padding: `${toRem(12)} ${toRem(12)} ${toRem(12)} ${toRem(16)}`,
});
const detailsIconCSS = css([
    iconSmallCSS,
    {
        marginInlineEnd: toRem(8),
    },
]);
// FUNCTIONS
export default function ContentHeader({ articlesCount, categoriesCount, children, featuredImageUrl, hideActions, isInternal, lastUpdated, title, }) {
    // HOOKS
    const { t } = useTranslation();
    // STATES
    const [copied, setCopied] = useState(false);
    // VARIABLES
    const dataFeaturedImage = featuredImageUrl ? true : undefined;
    const iconName = copied ? 'check' : 'clipboard';
    // FUNCTIONS
    function printOnClick() {
        window.print();
    }
    function copyLinkOnClick() {
        if (!copied) {
            navigator.clipboard.writeText(window.location.href);
            setCopied(true);
        }
    }
    // EFFECTS
    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false);
            }, 1000);
        }
    }, [copied]);
    return (<header className="kb-content-header" css={headerCSS} data-featured-image={dataFeaturedImage} dir="auto">
      {featuredImageUrl && (<div className="kb-content-header__featured-image-wrapper" css={featuredImageCSS}>
          <img className="kb-content-header__featured-image" css={imageCSS} src={featuredImageUrl}/>
        </div>)}
      <div className="kb-content-header__wrapper" css={contentCSS}>
        {!hideActions && (<ul className="kb-content-header__actions" css={actionsListCSS}>
            <li className="kb-content-header__actions-item" css={actionsListItemCSS}>
              <button aria-label={t('common.print')} className="kb-content-header__actions-button" css={actionsButtonCSS} onClick={printOnClick}>
                <Icon name="printer" className="kb-content-header__actions-icon" css={actionsIconCSS}/>
              </button>
            </li>
            <li className="kb-content-header__actions-item" css={actionsListItemCSS}>
              <button aria-label={t('common.copyTrigger')} className="kb-content-header__actions-button" css={actionsButtonCSS} onClick={copyLinkOnClick}>
                <Icon name={iconName} className="kb-content-header__actions-icon" css={actionsIconCSS}/>
              </button>
              {copied && (<div className="kb-content-header__actions-tooltip" css={actionsTooltipCSS}>
                  {t('common.copySuccess')}
                </div>)}
            </li>
          </ul>)}
        {title && (<h1 className="kb-content-header__title" css={titleCSS}>
            {title}
          </h1>)}
        {(categoriesCount || articlesCount || lastUpdated || isInternal) && (<ul className="kb-content-header__details" css={detailsListCSS}>
            {!!isInternal && (<li className="kb-content-header__details-item kb-content-header__banner" css={[detailsListItemCSS, detailsListItemBannerCSS]}>
                <Icon name="eye-off" className="kb-content-header__details-icon" css={detailsIconCSS}/>
                {t('common.internalUseOnly')}
              </li>)}
            {!!categoriesCount && (<li className="kb-content-header__details-item" css={detailsListItemCSS}>
                <Icon name="folder" className="kb-content-header__details-icon" css={detailsIconCSS}/>
                {t('common.categoryWithCount', { count: categoriesCount })}
              </li>)}
            {!!articlesCount && (<li className="kb-content-header__details-item" css={detailsListItemCSS}>
                <Icon name="file" className="kb-content-header__details-icon" css={detailsIconCSS}/>
                {t('common.articleWithCount', { count: articlesCount })}
              </li>)}
            {!!lastUpdated && (<li className="kb-content-header__details-item" css={detailsListItemCSS}>
                <Icon name="edit" className="kb-content-header__details-icon" css={detailsIconCSS}/>
                {t('common.lastUpdatedOn')} {lastUpdated}
              </li>)}
          </ul>)}
        {children}
      </div>
    </header>);
}
