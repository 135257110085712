import { css } from '@emotion/react';
import { themeOptions } from './themeOptions';
function getFontFallback(name) {
    const serifFontNames = [
        'Bitter',
        'IBM Plex Serif',
        'Lora',
        'Playfair Display',
        'PT Serif',
        'Roboto Slab',
    ];
    return serifFontNames.includes(name) ? 'serif' : 'sans-serif';
}
function getFontFamilyString(name, fallback) {
    if (name === 'System Default')
        return fallback;
    return `${name}, ${fallback}`;
}
function getFontStyles(name, weight) {
    if (name === 'System Default')
        return null;
    const importName = name.replace(' ', '+');
    return css `
    @import url('https://fonts.googleapis.com/css2?family=${importName}:wght@${weight}&display=swap');
  `;
}
const { fontBody, fontCode, fontHeadings } = themeOptions;
export const bodyFontFamily = getFontFamilyString(fontBody, getFontFallback(fontBody));
export const codeFontFamily = getFontFamilyString(fontCode, 'monospace');
export const headingFontFamily = getFontFamilyString(fontHeadings, getFontFallback(fontHeadings));
export const bodyFontStyles = getFontStyles(fontBody, '400;600');
export const codeFontStyles = getFontStyles(fontCode, '400');
export const headingFontStyles = getFontStyles(fontHeadings, '700');
