import { useEffect, useRef, useState } from 'react';
// FUNCTIONS
export default function useAtBottomOfPage({ tolerance = 0, }) {
    // REFS
    const didEnterTriggerArea = useRef(false);
    // STATE
    const [atBottomOfPage, setAtBottomOfPage] = useState(false);
    // FUNCTIONS
    const windowOnScroll = (event) => {
        const targetElement = event.target;
        const windowHeight = targetElement.scrollingElement.clientHeight;
        const windowScrollHeight = targetElement.scrollingElement.scrollHeight;
        const windowScrollTop = targetElement.scrollingElement.scrollTop;
        const triggerArea = windowScrollHeight - windowHeight - windowHeight * tolerance;
        // Entered trigger area
        if (didEnterTriggerArea.current === false &&
            windowScrollTop >= triggerArea) {
            setAtBottomOfPage(true);
            didEnterTriggerArea.current = true;
        }
        // Left trigger area
        else if (didEnterTriggerArea.current === true &&
            windowScrollTop < triggerArea) {
            setAtBottomOfPage(false);
            didEnterTriggerArea.current = false;
        }
    };
    // EFFECTS
    useEffect(() => {
        window.addEventListener('scroll', windowOnScroll);
        return () => {
            window.removeEventListener('scroll', windowOnScroll);
        };
    }, []);
    return atBottomOfPage;
}
