/** @jsx jsx */
/** @jsxRuntime classic */
import { css, jsx } from '@emotion/react';
import { useCategories, useTranslation } from '@elevio/kb-kit/lib/hooks';
import { Fragment } from 'react';
import { CategoryCard, CategoryCardPlaceholder, Grid, Search, } from '../components';
import { toRem } from '../functions';
import { useBreakpointValues } from '../hooks';
import { Footer, Header, MainHome, Nav, Page } from '../layout';
import { pageTitleCSS, textLargeCSS } from '../variables';
// STYLES
const titleCSS = css([
    pageTitleCSS,
    {
        color: 'var(--accentTextStrong)',
        textAlign: 'center',
        marginBlockEnd: toRem(8),
    },
]);
const textCSS = css([
    textLargeCSS,
    {
        color: 'var(--accentTextDefault)',
        textAlign: 'center',
    },
]);
const searchCSS = css({
    marginBlockStart: toRem(32),
    '&:first-child': {
        marginBlockStart: 0,
    },
});
// FUNCTIONS
export default function HomePage() {
    // HOOKS
    const homePageData = useCategories();
    const categories = homePageData.categories;
    const { t } = useTranslation();
    // VARIABLES
    const columns = useBreakpointValues({ xs: 1, sm: 2, md: 3 });
    const gap = useBreakpointValues({ xs: 16, sm: 24, lg: 32 });
    // RETURN
    return (<Page>
      <Nav hideSearch/>
      <Header>
        {t('home.title', '') && (<h1 className="kb-home__title" css={titleCSS}>
            {t('home.title')}
          </h1>)}
        {t('home.description', '') && (<p className="kb-home__description" css={textCSS}>
            {t('home.description')}
          </p>)}
        <div css={searchCSS}>
          <Search className="kb-home__search" large/>
        </div>
      </Header>
      <MainHome>
        <Grid columns={columns} gap={gap}>
          {categories.map((category, index) => {
            return <CategoryCard id={category.id} key={index}/>;
        })}
          {categories.length === 0 && (<Fragment>
              <CategoryCardPlaceholder />
              <CategoryCardPlaceholder />
              <CategoryCardPlaceholder />
              <CategoryCardPlaceholder />
              <CategoryCardPlaceholder />
              <CategoryCardPlaceholder />
            </Fragment>)}
        </Grid>
      </MainHome>
      <Footer />
    </Page>);
}
