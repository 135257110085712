/** @jsx jsx */
/** @jsxRuntime classic */
import { Article } from '@elevio/kb-kit/lib/components';
import { useArticleFragment, useArticleFull, useCategory, useLightbox, useTranslation, } from '@elevio/kb-kit/lib/hooks';
import { css, jsx } from '@emotion/react';
import { ContentHeader, SidebarList, SidebarListArticle, SidebarListSubCategory, } from '../components';
import ArticleFeedback from '../components/ArticleFeedback';
import LightBox from '../components/LightBox';
import { toRem } from '../functions';
import { Aside, Breadcrumbs, Content, Footer, MainArticle, Nav, Page, } from '../layout';
// STYLES
const articleBodyCSS = css({
    borderBlockStart: 'thin solid var(--border)',
    padding: toRem(32),
    '@media (min-width: 768px)': {
        padding: toRem(40),
    },
    '@media (min-width: 1200px)': {
        padding: toRem(48),
    },
});
// FUNCTIONS
export default function ArticlePage() {
    // HOOKS
    const article = useArticleFull();
    const { t } = useTranslation();
    const { image, hide, onLightboxElementClick } = useLightbox();
    // VARIABLES
    const featuredImageUrl = (article === null || article === void 0 ? void 0 : article.featuredImageUrl) || undefined;
    const lastUpdated = article === null || article === void 0 ? void 0 : article.lastPublishedAtLocal;
    const relatedArticles = article === null || article === void 0 ? void 0 : article.relatedArticles;
    const title = article === null || article === void 0 ? void 0 : article.title;
    const categoryId = article === null || article === void 0 ? void 0 : article.categoryId;
    const isInternal = article === null || article === void 0 ? void 0 : article.accessControl.isInternal;
    // RETURN
    return (<Page>
      <Nav />
      <MainArticle>
        <Breadcrumbs />
        <Content>
          <ContentHeader featuredImageUrl={featuredImageUrl} isInternal={isInternal} lastUpdated={lastUpdated} title={title}/>
          <div className="kb-content-body" css={articleBodyCSS}>
            <LightBox image={image} isShowing={!!image} hide={hide}/>
            <Article.Body className="kb-article" noStyles onClick={onLightboxElementClick}/>
          </div>
          <ArticleFeedback />
        </Content>
        <Aside>
          {categoryId && <MoreArticles categoryId={categoryId}/>}
          {relatedArticles && relatedArticles.length > 0 && (<RelatedArticles relatedArticles={relatedArticles}/>)}
        </Aside>
      </MainArticle>
      <Footer />
    </Page>);
}
function MoreArticles({ categoryId }) {
    // HOOKS
    const category = useCategory({ categoryId });
    // RETURN
    if (category) {
        const { articles, subCategories, title } = category;
        return (<SidebarList title={title}>
        {articles.map((articleId, index) => {
                return <ArticleItem articleId={articleId} key={index}/>;
            })}
        {subCategories.map((categoryId, index) => {
                return <SubCategoryItem categoryId={categoryId} key={index}/>;
            })}
      </SidebarList>);
    }
    else {
        return null;
    }
}
function ArticleItem({ articleId }) {
    // HOOKS
    const article = useArticleFragment({ articleId });
    // RETURN
    if (article) {
        const { id, title } = article;
        return <SidebarListArticle id={id} title={title}/>;
    }
    else {
        return null;
    }
}
function SubCategoryItem({ categoryId }) {
    // HOOKS
    const category = useCategory({ categoryId });
    // RETURN
    if (category) {
        const { articles, title } = category;
        return (<SidebarListSubCategory title={title}>
        {articles.map((articleId, index) => {
                return <ArticleItem articleId={articleId} key={index}/>;
            })}
      </SidebarListSubCategory>);
    }
    else {
        return null;
    }
}
function RelatedArticles({ relatedArticles }) {
    // HOOKS
    const { t } = useTranslation();
    // RETURN
    return (<SidebarList title={t('article.relatedArticles')}>
      {relatedArticles.map((articleId, index) => {
            return <ArticleItem articleId={articleId} key={index}/>;
        })}
    </SidebarList>);
}
