/** @jsx jsx */
/** @jsxRuntime classic */
import { css, jsx } from '@emotion/react';
import { toRem } from '../functions';
// STYLES
const gridCSS = (columns, gap) => {
    const gridTemplateColumns = `repeat(${columns}, 1fr)`;
    const gapValue = toRem(gap);
    return css({
        display: 'grid',
        gap: gapValue,
        gridTemplateColumns,
    });
};
// FUNCTIONS
export default function Grid({ children, columns, gap }) {
    return (<div className="kb-grid" css={gridCSS(columns, gap)}>
      {children}
    </div>);
}
