/** @jsx jsx */
/** @jsxRuntime classic */
import { css, jsx } from '@emotion/react';
import classNames from 'classnames';
import { useRef, useEffect, } from 'react';
import { Icon } from '../components';
import { toRem } from '../functions';
import { iconMediumCSS, textMediumCSS } from '../variables';
// STYLES
const inputCSS = css([
    textMediumCSS,
    {
        alignItems: 'start',
        borderColor: 'var(--border)',
        borderStyle: 'solid',
        borderWidth: 0,
        color: 'var(--textDefault)',
        cursor: 'text',
        display: 'flex',
        fill: 'var(--textSubtle)',
        paddingBlockEnd: toRem(16),
        position: 'relative',
        transitionProperty: 'border-color',
        transitionDuration: '200ms',
        transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
        '@media (min-width: 1200px)': {
            paddingBlockEnd: toRem(20),
        },
        '&::before': {
            borderColor: 'inherit',
            borderStyle: 'inherit',
            borderWidth: `${toRem(2)} 0 0 0`,
            bottom: 0,
            content: `''`,
            display: 'block',
            left: 0,
            pointerEvents: 'none',
            position: 'absolute',
            right: 0,
        },
        '&:hover': {
            borderColor: 'var(--textSubtle)',
        },
        '&:focus-within': {
            borderColor: 'var(--accent)',
        },
        '&[data-invalid="true"]': {
            borderColor: 'var(--negativeDefault)',
            color: 'var(--negativeDefault)',
            fill: 'var(--negativeDefault)',
        },
    },
]);
const inputIconCSS = css([
    iconMediumCSS,
    {
        marginInlineEnd: toRem(12),
    },
]);
const inputControlCSS = css({
    flexGrow: 1,
    outline: 'none',
});
const inputErrorIconCSS = css([
    iconMediumCSS,
    {
        marginInlineStart: toRem(12),
    },
]);
const textareaControlCSS = ({ autoGrow }) => css([
    inputControlCSS,
    {
        minHeight: toRem(24),
        resize: 'vertical',
        ...(autoGrow && {
            overflow: 'hidden',
            resize: 'none',
        }),
    },
]);
// FUNCTIONS
export function TextInput({ className, icon, invalid, ...props }) {
    // REFS
    const controlRef = useRef(null);
    // FUNCTIONS
    function onClick() {
        var _a;
        (_a = controlRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }
    // RETURN
    return (<div className={classNames('kb-input', className)} css={inputCSS} data-invalid={invalid} onClick={onClick}>
      {icon && (<Icon className="kb-input__icon" css={inputIconCSS} name={icon}/>)}
      <input aria-invalid={invalid} className="kb-input__control" css={inputControlCSS} ref={controlRef} {...props}/>
      {invalid && (<Icon className="kb-input__icon" css={inputErrorIconCSS} name="alert-octagon"/>)}
    </div>);
}
export function TextArea({ className, autoGrow = false, icon, invalid, ...props }) {
    // REFS
    const controlRef = useRef(null);
    // FUNCTIONS
    function resizeTextarea() {
        const controlRefCurrent = controlRef.current;
        if (controlRefCurrent) {
            controlRefCurrent.style.height = 'initial';
            // must reset height first or scrollHeight will not get smaller when rows are removed
            controlRefCurrent.style.height = `${controlRefCurrent.scrollHeight}px`;
        }
    }
    function onClick() {
        var _a;
        (_a = controlRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }
    function onKeyUp() {
        if (autoGrow)
            resizeTextarea();
    }
    // EFFECTS
    useEffect(() => {
        if (autoGrow) {
            window.addEventListener('resize', resizeTextarea);
            return () => {
                window.removeEventListener('resize', resizeTextarea);
            };
        }
    }, [autoGrow]);
    // RETURN
    return (<div className={classNames('kb-input', className)} css={inputCSS} data-invalid={invalid} onClick={onClick}>
      {icon && (<Icon className="kb-input__icon" css={inputIconCSS} name={icon}/>)}
      <textarea aria-invalid={invalid} className="kb-input__control" css={textareaControlCSS({ autoGrow })} onKeyUp={onKeyUp} ref={controlRef} rows={1} {...props}/>
      {invalid && (<Icon className="kb-input__icon" css={inputErrorIconCSS} name="alert-octagon"/>)}
    </div>);
}
