/** @jsx jsx */
/** @jsxRuntime classic */
import { css, jsx } from '@emotion/react';
import classNames from 'classnames';
import { toRem } from '../functions';
import { textMediumCSS } from '../variables';
// STYLES
const buttonCSS = ({ primary }) => css([
    textMediumCSS,
    {
        borderRadius: toRem(4),
        display: 'block',
        fontWeight: 600,
        outline: 'none',
        padding: `${toRem(8)} ${toRem(12)}`,
        position: 'relative',
        transitionProperty: 'opacity',
        transitionDuration: '200ms',
        transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
        ...(!primary && {
            backgroundColor: 'var(--backgroundContent)',
            color: 'var(--accent)',
        }),
        ...(primary && {
            backgroundColor: 'var(--accent)',
            color: 'var(--accentTextStrong)',
        }),
        '@media (min-width: 1200px)': {
            borderRadius: toRem(6),
            padding: `${toRem(10)} ${toRem(16)}`,
        },
        '&:hover': {
            opacity: 0.75,
        },
        '&:active': {
            opacity: 0.5,
        },
        '&:focus': {
            '&::before': {
                borderColor: 'var(--accent)',
                borderRadius: toRem(8),
                borderStyle: 'solid',
                borderWidth: toRem(2),
                bottom: toRem(-4),
                content: `''`,
                display: 'block',
                left: toRem(-4),
                pointerEvents: 'none',
                position: 'absolute',
                right: toRem(-4),
                top: toRem(-4),
                '@media (min-width: 1200px)': {
                    borderRadius: toRem(10),
                },
            },
        },
        '&:disabled': {
            cursor: 'not-allowed',
            opacity: 0.5,
        },
    },
]);
// FUNCTIONS
export default function Button({ children, className, primary = false, ...props }) {
    return (<button children={children} className={classNames('kb-button', className)} css={buttonCSS({ primary })} {...props}/>);
}
