/** @jsx jsx */
/** @jsxRuntime classic */
import { useLanguages } from '@elevio/kb-kit/lib/hooks';
import { css, jsx } from '@emotion/react';
import { useEffect, useRef, useState, } from 'react';
import { Icon } from '../components';
import { toRem, uniqueId } from '../functions';
import { menuButtonCSS, menuIconCSS, menuLabelCSS } from '../layout';
// STYLES
const containerCSS = css({
    position: 'relative',
});
const wrapperCSS = css({
    backgroundColor: 'var(--accentBackgroundDropdown)',
    borderRadius: toRem(8),
    boxShadow: `0 ${toRem(2)} ${toRem(4)} rgba(0,0,0,.08), 0 ${toRem(8)} ${toRem(32)} rgba(0,0,0,.16)`,
    marginBlockStart: toRem(8),
    outline: 'none',
    padding: toRem(12),
    position: 'relative',
    '&::before': {
        backgroundColor: 'inherit',
        blockSize: toRem(8),
        borderTopLeftRadius: toRem(2),
        content: `''`,
        display: 'block',
        inlineSize: toRem(8),
        position: 'absolute',
        right: toRem(16),
        top: toRem(-4),
        transform: 'rotate(45deg)',
    },
    '@media (min-width: 1024px)': {
        position: 'absolute',
        right: 0,
        top: '100%',
    },
});
const itemCSS = css({
    borderRadius: toRem(6),
    color: 'var(--accentTextSubtle)',
    cursor: 'pointer',
    padding: `${toRem(8)} ${toRem(32)} ${toRem(8)} ${toRem(40)}`,
    position: 'relative',
    transitionProperty: 'color',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0,0,0,.4)',
    '&:hover': {
        color: 'var(--accentTextDefault)',
    },
    '&:active': {
        color: 'var(--accentTextStrong)',
    },
    '&[data-active="true"]': {
        backgroundColor: 'var(--accent)',
        color: 'var(--accentTextStrong)',
        opacity: 1,
    },
    '&[data-focus="true"]': {
        boxShadow: `inset 0 0 0 ${toRem(2)} var(--accentAccent)`,
    },
});
const iconCSS = css({
    blockSize: toRem(16),
    inlineSize: toRem(16),
    left: toRem(8),
    padding: toRem(4),
    position: 'absolute',
    top: toRem(8),
});
// FUNCTIONS
export default function LanguagePicker() {
    var _a;
    // REFS
    const languagePickerTriggerRef = useRef(null);
    const languagePickerMenuRef = useRef(null);
    // HOOKS
    const { selectedLanguage, supportedLanguages, setCurrentLanguageId } = useLanguages();
    const { focusedIndex, nextFocusedIndex, prevFocusedIndex, selectedLanguageIndex, setFocusedIndex, } = useFocusedIndex(selectedLanguage, supportedLanguages);
    // STATES
    const [languagePickerOpen, setLanguagePickerOpen] = useState(false);
    const [languagePickerMenuID] = useState(uniqueId('menu-'));
    const [languagePickerTriggerID] = useState(uniqueId('button-'));
    // VARIABLES
    const ariaActiveDescendant = `${(_a = languagePickerMenuRef.current) === null || _a === void 0 ? void 0 : _a.id}-item-${selectedLanguageIndex}`;
    const menuItemActive = (index) => index === selectedLanguageIndex;
    const menuItemFocus = (index) => index === focusedIndex;
    const menuItemId = (index) => { var _a; return `${(_a = languagePickerMenuRef.current) === null || _a === void 0 ? void 0 : _a.id}-item-${index}`; };
    // FUNCTIONS
    function languagePickerOnClose() {
        var _a;
        setLanguagePickerOpen(false);
        (_a = languagePickerTriggerRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        setFocusedIndex(selectedLanguageIndex);
    }
    function languagePickerTriggerOnClick() {
        setLanguagePickerOpen(true);
    }
    function languagePickerTriggerOnKeyDown(event) {
        if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
            event.preventDefault();
            setLanguagePickerOpen(true);
        }
    }
    function windowOnClick(event) {
        event.stopPropagation();
        languagePickerOnClose();
    }
    function menuOnClick(event) {
        event.stopPropagation();
    }
    function menuOnKeyDown(event) {
        if (event.key === 'Escape' || event.key === 'Tab') {
            event.preventDefault();
            languagePickerOnClose();
        }
        else if (event.key === 'ArrowDown') {
            event.preventDefault();
            setFocusedIndex(nextFocusedIndex);
        }
        else if (event.key === 'ArrowUp') {
            event.preventDefault();
            setFocusedIndex(prevFocusedIndex);
        }
        else if (event.key === 'Enter') {
            event.preventDefault();
            setCurrentLanguageId(supportedLanguages[focusedIndex].id);
            languagePickerOnClose();
        }
    }
    function menuItemOnClick(id) {
        setCurrentLanguageId(id);
        languagePickerOnClose();
    }
    // EFFECTS
    useEffect(() => {
        var _a;
        if (languagePickerOpen) {
            (_a = languagePickerMenuRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            window.addEventListener('click', windowOnClick);
        }
        return () => {
            window.removeEventListener('click', windowOnClick);
        };
    }, [languagePickerOpen]);
    // RETURN
    return (<li className="kb-nav__menu-list-item" css={containerCSS}>
      <button aria-controls={languagePickerMenuID} aria-expanded={languagePickerOpen} aria-haspopup="true" className="kb-nav__menu-button" css={menuButtonCSS} id={languagePickerTriggerID} onClick={languagePickerTriggerOnClick} onKeyDown={languagePickerTriggerOnKeyDown} ref={languagePickerTriggerRef}>
        <Icon name="globe" className="kb-nav__button-icon" css={menuIconCSS}/>
        <span className="kb-nav__button-label" css={menuLabelCSS}>
          {selectedLanguage.label}
        </span>
        <Icon name="chevron-down" className="kb-nav__button-icon" css={menuIconCSS}/>
      </button>
      <ul aria-activedescendant={ariaActiveDescendant} aria-labelledby={languagePickerTriggerID} className="kb-nav__language-picker" css={wrapperCSS} hidden={!languagePickerOpen} id={languagePickerMenuID} onClick={menuOnClick} onKeyDown={menuOnKeyDown} ref={languagePickerMenuRef} role="menu" tabIndex={-1}>
        {supportedLanguages.map((language, index) => {
            return (<li className="kb-nav__language-picker-item" css={itemCSS} data-active={menuItemActive(index)} data-focus={menuItemFocus(index)} onClick={() => menuItemOnClick(language.id)} id={menuItemId(index)} key={index} role="menuitem">
              {menuItemActive(index) && (<Icon name="check" className="kb-nav__language-picker-icon" css={iconCSS}/>)}
              {language.label}
            </li>);
        })}
      </ul>
    </li>);
}
// HOOKS
function useFocusedIndex(selectedLanguage, supportedLanguages) {
    const selectedLanguageIndex = supportedLanguages.findIndex((language) => {
        return language.id === selectedLanguage.id;
    });
    const [focusedIndex, setFocusedIndex] = useState(selectedLanguageIndex);
    const nextFocusedIndex = focusedIndex === supportedLanguages.length - 1 ? 0 : focusedIndex + 1;
    const prevFocusedIndex = focusedIndex === 0 ? supportedLanguages.length - 1 : focusedIndex - 1;
    return {
        focusedIndex,
        nextFocusedIndex,
        prevFocusedIndex,
        selectedLanguageIndex,
        setFocusedIndex,
    };
}
