import { useMediaQuery } from 'react-responsive';
export default function useBreakpointValues({ xs, sm = xs, md = sm, lg = md, }) {
    const isLg = useMediaQuery({ minWidth: 1120 });
    const isMd = useMediaQuery({ minWidth: 1024 });
    const isSm = useMediaQuery({ minWidth: 768 });
    if (isLg) {
        return lg;
    }
    else if (isMd) {
        return md;
    }
    else if (isSm) {
        return sm;
    }
    else {
        return xs;
    }
}
