/** @jsx jsx */
/** @jsxRuntime classic */
import { css, jsx } from '@emotion/react';
import classNames from 'classnames';
import { toRem } from '../functions';
// STYLES
const skeletonOuterCSS = ({ outerHeight, width }) => {
    return css({
        alignItems: 'center',
        blockSize: toRem(outerHeight),
        display: 'flex',
        inlineSize: toRem(width),
        maxWidth: '100%',
    });
};
const skeletonInnerCSS = ({ innerHeight }) => {
    return css({
        backgroundColor: 'var(--border)',
        borderRadius: toRem(3),
        blockSize: toRem(innerHeight),
        inlineSize: '100%',
    });
};
// FUNCTIONS
export default function Skeleton({ className, innerHeight, outerHeight, width, ...props }) {
    return (<div className={classNames('kb-skeleton', className)} css={skeletonOuterCSS({ outerHeight, width })} role="presentation" {...props}>
      <div className="kb-skeleton__inner" css={skeletonInnerCSS({ innerHeight })} role="presentation"/>
    </div>);
}
